import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  Fragment,
} from "react";

// MUI
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

// actions

// styles
import "../../styles/Admin.css";

// dependents
import { AlertContext } from "../../../Context/AlertContextProvider";
import { createBulletin, updateBulletin } from "../../../Actions";
import { BulletinContext } from "../../../Context/BulletinContextProvider";

// component
const CreateBulletin = (props) => {
  const {
    bulletinId,
    type,
    imageUrl,
    message,
    title,
    effectiveStartDate,
    effectiveEndDate,
  } = props;
  const { handleAlertOpen, setMessageType, setMessage } = useContext(
    AlertContext
  );
  const { setUpdate } = useContext(BulletinContext);

  const [open, setOpen] = useState(props.open);

  const [bulletinText, setBulletinText] = useState("");
  const [bulletinTitle, setBulletinTitle] = useState("");
  const [previewUrl, setPreviewUrl] = useState();
  const [file, setFile] = useState();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date("2054-08-29T21:11:54"));
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    if (type === "Edit") {
      // console.log(props);
      setPreviewUrl(imageUrl);
      setBulletinText(message);
      setBulletinTitle(title);
      setStartDate(effectiveStartDate);
      setEndDate(effectiveEndDate);
    }

    if (!file) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [
    file,
    type,
    imageUrl,
    message,
    title,
    effectiveStartDate,
    effectiveEndDate,
  ]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setBulletinText("");
    setFile();
    setPreviewUrl();
    setOpen(false);
  };

  const handleUpload = () => {
    hiddenFileInput.current.click();
  };

  const handleFileUpload = (event) => {
    let pickedFile;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);
    }
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const handleBulletinTitle = (event) => {
    const value = event.target.value;
    setBulletinTitle(value);
  };

  const handleSubmit = () => {
    const sdate =
      startDate.length < 11
        ? startDate
        : startDate.toISOString().substring(0, 10);
    const edate =
      endDate.length < 11 ? endDate : endDate.toISOString().substring(0, 10);

    // -- params
    // effectStartDate
    // effectEndDate
    // title
    // message

    if (type === "Create") {
      let formData = new FormData();
      formData.append("image", file);
      formData.append("effectStartDate", sdate);
      formData.append("effectEndDate", edate);
      formData.append("title", bulletinTitle);
      formData.append("message", bulletinText);
      createBulletin(formData)
        .then((result) => {
          setMessage("Bulletin Created");
          setMessageType("success");
          handleAlertOpen();
          handleClose();
          setUpdate(true);
        })
        .catch((err) => {
          setMessage("Failed Creating Bulletin");
          setMessageType("error");
          handleAlertOpen();
        });
    }
    if (type === "Edit") {
      const data = {
        title: bulletinTitle,
        message: bulletinText,
        effectiveStartDate: sdate,
        effectiveEndDate: edate,
      };

      updateBulletin(bulletinId, data)
        .then(() => {
          setMessage("Bulletin Updated");
          setMessageType("success");
          handleAlertOpen();
          handleClose();
          setUpdate(true);
        })
        .catch(() => {
          setMessage("Failed to update Bulletin");
          setMessageType("error");
          handleAlertOpen();
        });
    }
  };

  const handleBulletin = (event) => {
    const value = event.target.value;
    setBulletinText(value);
  };

  const canCreate = () => {
    if (bulletinText.length > 0 && bulletinTitle.length > 0) {
      return false;
    }
    return true;
  };

  return (
    <Fragment>
      <Button
        size="small"
        className="admin__bulletin-create"
        onClick={handleOpen}
      >
        {props.type}
      </Button>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle
          className="Admin__create-user-header"
          id="alert-dialog-title"
        >
          {`${props.type} Bulletin`}
        </DialogTitle>
        <DialogContent dividers>
          <div className="admin__image-upload admin__image-upload-center">
            <div className="admin__image-upload__preview">
              {previewUrl && <img src={previewUrl} alt="Preview" />}
              {!previewUrl && <p>Please pick an image.</p>}
            </div>
          </div>
          <form>
            <TextField
              id="filled-multiline-static"
              className="admin__input-announcement"
              label="Bulletin Title"
              value={bulletinTitle}
              onChange={handleBulletinTitle}
              placeholder="Write a Bulletin Title!"
              variant="filled"
            />
            <TextField
              id="filled-multiline-static"
              className="admin__input-announcement"
              label="Bulletin"
              multiline
              value={bulletinText}
              rows={2}
              onChange={handleBulletin}
              placeholder="Bulletin!"
              variant="filled"
            />
            <div className="admin__date-picker">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/DD/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Start Date"
                  value={startDate}
                  onChange={handleStartDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />

                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/DD/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="End Date"
                  value={endDate}
                  onChange={handleEndDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <input
              className="admin__bulletin-image-uploader"
              type="file"
              ref={hiddenFileInput}
              onChange={handleFileUpload}
              accept="image/x-png,image/jpeg"
            ></input>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button
            disabled={props.type === "Create" ? false : true}
            onClick={handleUpload}
            color="primary"
            autoFocus
          >
            Browse Image
          </Button>
          <Button
            onClick={handleSubmit}
            className="Admin__create-user"
            color="primary"
            autoFocus
            disabled={canCreate()}
          >
            {props.type === "Create" ? props.type : "Update"}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default CreateBulletin;
