import React, { useEffect, useState } from "react";

//MUI
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Tooltip from "@material-ui/core/Tooltip";
import { Launch } from "@material-ui/icons";

//Styles
import "../styles/SiteStatus.css";

//Actions
import { startStop } from "../../Actions/index";

export default function EC2StatusItem (props) {

  const handleClick = async (id, ss) => {
    startStop(id, ss).then(result => {
      if (result.status === 200) {
        props.refresh();
      }
    });
  };

  const renderButton = data => {
    return data.status == "running" ? (
        <Button
          variant="contained"
          style={{ backgroundColor: "#f44336", color: "#FFFFFF", margin: "25px auto 0px"}}
          onClick={() => handleClick(data.instanceId, "STOP")}
        >
          Stop
        </Button>
      ) : (
        data.status == "terminated"
        ? undefined
        : <Button
          variant="contained"
          style={{ backgroundColor: "#4caf50", color: "#FFFFFF", margin: "25px auto 0px"}}
          onClick={() => handleClick(data.instanceId, "START")}
        >
          Start
        </Button>
      );
  };

  const ec2Data = props.ec2Data;

  let siteStatus;

  switch (ec2Data?.status) {
    case "running":
      siteStatus = (
        <Alert severity="success">
          EC2 {ec2Data?.status}
        </Alert>
      )
      break;
    case "pending":
    case "stopping":
      siteStatus = (
        <Alert severity="warning">
          EC2 {ec2Data?.status}
        </Alert>
      )
      break;
    case "shutting-down":
      siteStatus = (
        <Alert severity="error">
          EC2 {ec2Data?.status}
        </Alert>
      )
      break;
    default: 
      if (ec2Data) {
        siteStatus = (
          <Alert severity="error">
            EC2 {ec2Data?.status}
          </Alert>
        )
      } else {
        siteStatus = undefined
      }
  }

  let appVersion;

  if (ec2Data?.apiStatus.version === "unknown") {
    appVersion = `(${ec2Data?.apiStatus.version})`;
  } else if (ec2Data?.apiStatus.version === "none") {
    appVersion = "";
  } else if (ec2Data?.apiStatus.version) {
    appVersion = `(v${ec2Data?.apiStatus.version})`;
  }

  let appStatus;

  if (ec2Data?.apiStatus.applicationStatus === "No API Available") {
    appStatus = ec2Data?.apiStatus.applicationStatus;
  } else if (ec2Data?.apiStatus.applicationStatus) {
    appStatus = `App ${ec2Data?.apiStatus.applicationStatus}`
  }

  return (
    <div style={{borderBottom: "1px solid #838383", textAlign: "left", padding: "10px 10px 25px", display: !props.isVisible ? "none" : "block"}}>
      <div style={{display: "inline-block", verticalAlign: "top", width: "75%"}}>
        <Typography variant="h5" style={{fontWeight: "bold", padding: "0px 0px 5px"}}>
          {ec2Data.name}
        </Typography>
        { ec2Data.name.includes(".com") ? (
            <Typography>
              <a href={`https://${ec2Data.name}`} target="_blank" style={{ textDecoration: "none", cursor: "pointer", color: "#838383"}}>
                <Launch style={{marginRight: "3px", verticalAlign: "middle"}}></Launch> {ec2Data.name}
              </a>
            </Typography>
          ) : undefined
        }
        { ec2Data ? renderButton(ec2Data) : undefined}
      </div>
      <div style={{display: "inline-block", verticalAlign: "top", width: "25%", minHeight: "125px", position: "relative"}}>
        <div style={{margin: "auto auto 10px"}}>
          { ec2Data?.type ? (
              <Typography style={{display: "inline-block", verticalAlign: "middle", marginRight: "20px"}}>
                <b>Type:</b> {ec2Data?.type}
              </Typography>
            ) : undefined 
          }
          { ec2Data?.databaseInstance ? (
              <Typography style={{display: "inline-block", verticalAlign: "middle"}}>
                <b>Database:</b> {ec2Data?.databaseInstance.split('.')[0]}
              </Typography>
            ) : undefined 
          }
        </div>
        <div style={{margin: "auto auto 10px"}}>
          {siteStatus}
        </div>
        <Tooltip
          title={
            <div>
              <div>
                <Typography variant="body1">
                  RESPONSE TIME: {ec2Data?.apiStatus.responseTime ?? ''} ms
                </Typography>
              </div>
              <br></br>
              <div>
                <Typography variant="body1">
                  START TIME: {ec2Data?.startTime + " (UTC)"}
                </Typography>
              </div>
            </div>
          }>
          {!ec2Data ? (
            <div></div>
          ) : ec2Data.apiStatus.version != "unknown" ? (
            <Alert severity="success">
              {`${appStatus} ${appVersion}`}
            </Alert>
          ) : (
            <Alert severity="error">
              {`${appStatus}`}
            </Alert>
          )
          }
        </Tooltip>
      </div>
    </div>
  );
}