// actions
import { logout } from "../Actions";

export const setUser = (user) => {
  let a = new Date();
  user.will_expire_at = a.setMinutes(a.getMinutes() + 720);
  localStorage.setItem("userDetails", JSON.stringify(user));
};

export const getUser = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  
  if (userDetails && userDetails.will_expire_at > new Date().getTime()) {
    return userDetails;
  }

  return {};
};
