import React, { useState, useEffect } from "react";
import { getB2BServerPreview } from "../../Actions";
import { Button, Chip, CircularProgress, Collapse, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

function getFileSize (size) {
  let fileSize = size;
  let sizeType = "B";

  if (fileSize * .000000001 > 1) {
    fileSize = fileSize * .000000001;
    sizeType = "GB";
  } else if (fileSize * .000001 > 1) {
    fileSize = fileSize * .000001;
    sizeType = "MB";
  } else if (fileSize * .001 > 1) {
    fileSize = fileSize * .001;
    sizeType = "KB";
  }

  return fileSize.toFixed(2) + ' ' + sizeType;
}

function ServerDirectory (props) {
  const [isOpen, setIsOpen] = useState(props.level === 1 ? true : false);

  const directoryRegex = props.partner.host_directory?.charAt(0) === '/' ? new RegExp(`^${props.partner.host_directory}$`) : new RegExp(`${props.partner.host_directory}$`, "g");
  const isSelectedDirectory = directoryRegex.test(props.path.replace("//", "/"));
  const has832Files = props.data.contents.find(item => item.name.includes(".832"));
  const fileCount = props.data.contents.filter(item => item.type === "f")?.length;
  const directoryCount = props.data.contents.filter(item => item.type === "d")?.length;

  const contents = props.data.contents?.sort((a, b) => {
    if (new Date(a.modifiedAt).getTime()) {
      return new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime();
    } else {
      return b.name.localeCompare(a.name);
    }
    
  }).map(item => {
    if (item.type === 'd') {
      return <ServerDirectory key={item.id} data={item} partner={props.partner} path={props.path + item.name} level={props.level + 1}/>
    } else {
      return <ServerFile key={item.id} data={item} partner={props.partner} level={props.level + 1}/>
    }  
  });

  return (  
    <>
      <ListItem button onClick={() => setIsOpen(!isOpen)} style={{borderBottom: "1px solid #BDBDBD", paddingLeft: (props.level * 15) + "px"}}>
        <ListItemText>
          <b>{props.data.name}</b>
          { has832Files ? (
              <Chip label="832 Files" style={{backgroundColor: "#142e3e", color: "#28c4fc", marginLeft: "10px"}}/>
            ) : undefined
          }
          { isSelectedDirectory ? (
              <Chip label="Selected Directory" style={{backgroundColor: "#28c4fc", color: "#FFFFFF", marginLeft: "10px"}}/>
            ) : undefined
          }
          { !props.data.contents.length ? (
              <Chip label="Empty" style={{backgroundColor: "#37373e", color: "#FFFFFF", marginLeft: "10px"}}/>
            ) : undefined
          }
        </ListItemText>
        { fileCount ? (
            <Typography style={{margin: "auto 5px"}}>{fileCount} Files</Typography>
          ) : undefined
        }
        { fileCount && directoryCount ? " / " : undefined}
        { directoryCount ? (
            <Typography style={{margin: "auto 5px"}}>{directoryCount} directories</Typography>
          ) : undefined
        }
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List disablePadding>
          {contents.length ? contents : (
            <ListItem style={{borderBottom: "1px solid #BDBDBD", paddingLeft: ((props.level + 1) * 15) + "px"}}>
              <ListItemText>No content available for this directory</ListItemText>
            </ListItem>
          )}
        </List>
      </Collapse>
    </>
  )
}

function ServerFile (props) {
  let modifiedDate = new Date(props.data.modifiedAt);

  if (/^[A-z]{3}\s+[0-9]{1,2}\s+[0-9]{2}:[0-9]{2}$/.test(props.data.modifiedAt)) {
    modifiedDate.setFullYear(new Date().getFullYear());
  }

  const is832 = props.data.name.includes(".832");
  let isProcessedByExt = false;
  let isProcessedBySP = false;

  if (props.partner?.secondary_b2b) {
    const startingChars = props.data.name.toLowerCase().substring(0,2);

    isProcessedByExt = startingChars.charAt(0) === "x";
    isProcessedBySP = startingChars.charAt(1) === "x";
  } else {
    isProcessedBySP = props.data.name.toLowerCase().substring(0,1) === "x";
  }

  return (  
    <ListItem disabled={!is832} style={{borderBottom: "1px solid #BDBDBD", paddingLeft: (props.level * 15) + "px"}}>
      <ListItemText>
        {props.data.name}
        { is832 && isProcessedByExt ? (
            <Chip label="Processed By Primary" style={{marginLeft: "15px"}}/>
          ) : undefined
        }
        { is832 && isProcessedBySP ? (
            <Chip label="Processed By SP" style={{backgroundColor: "#4caf50", color: "#FFFFFF", marginLeft: "15px"}}/>
          ) : undefined
        }
      </ListItemText>
      <div style={{textAlign: "right"}}>
        { modifiedDate.getTime() ? (
            <Typography variant="subtitle2">{modifiedDate.toLocaleString()}</Typography>
          ) : undefined
        }
        <Typography variant="subtitle2">{getFileSize(props.data.size)}</Typography>
      </div>
      
    </ListItem>
  )
}

export default function B2BServerPreview (props) {
  const [fileStructure, setFileStructure] = useState();
  const [error, setError] = useState();
  const [refresh, setRefresh] = useState(true);

  const isValidated = props.partner?.pass && props.partner?.user && props.partner?.alternate_host && props.partner?.alternate_port;
  const credentialAlertRegex = /(530)|(error connecting client)/i;
  const hasError = props.partner?.last_run_status !== 'FAIL' && credentialAlertRegex.test(JSON.stringify(props.partner?.last_run_message))

  useEffect(() => {
    function getFileStructure() {
      getB2BServerPreview(props.partner.edi_id).then(result => {
        setFileStructure(result.data.fileStructure);
        setError(undefined);
      }).catch(err => {
        setFileStructure([]);
        setError("An error occurred while retrieving the file structure. Please contact a dev for more info.");
      })
    }

    if (hasError) return;

    if (isValidated && refresh && props.partner?.edi_id) {
      setFileStructure(undefined);
      getFileStructure();
      setRefresh(false);
    }
  }, [props.partner?.edi_id, refresh])

  const fileStructureItems = fileStructure?.map(item => {
    if (item.type === 'd') {
      return <ServerDirectory key={item.id} data={item} partner={props.partner} path={item.name} level={1}/>
    } else {
      return <ServerFile key={item.id} data={item} partner={props.partner} level={1}/>
    }  
  });

  if (!isValidated) {
    return (
      <div style={{margin: "20px"}}>
        <Typography>Please make sure the following fields are filled out in the settings section before returning to this page.</Typography>
        <List>
          <ListItem style={{padding: "0px 15px"}}>
            <ListItemText>- Username</ListItemText>
          </ListItem>
          <ListItem style={{padding: "0px 15px"}}>
            <ListItemText>- Password</ListItemText>
          </ListItem>
          <ListItem style={{padding: "0px 15px"}}>
            <ListItemText>- Host</ListItemText>
          </ListItem>
          <ListItem style={{padding: "0px 15px"}}>
            <ListItemText>- Port</ListItemText>
          </ListItem>
        </List>
      </div>
    )
  }

  if (hasError) {
    return (
      <div style={{margin: "20px"}}>
        <Typography>This B2B connection is currently having login failures. Please make any necessary changes to the credentials and try again.</Typography>
      </div>
    )
  }

  return (
    <div>
      <Button variant="contained" style={{margin: "20px"}} onClick={() => setRefresh(true)}>Refresh Preview</Button>
      <div style={{margin: "10px 20px"}}>
        <Typography><b>Provider Type:</b> {props.partner?.secondary_b2b ? "Secondary" : "Primary"}</Typography>
      </div>
      <div style={{backgroundColor: "#F2F2F2", padding: "5px 15px 50px", maxHeight: "55vh", overflowY: "scroll", position: "relative"}}>
        { error ? (
            <div style={{margin: "20px"}}>
              <Typography>{error}</Typography>
            </div>
          ) : undefined
        }
        { fileStructure ? ( 
            <List style={{padding: "5px"}}>
              {fileStructureItems}
            </List>
          ) : (
            <div style={{margin: "20px", textAlign: "center"}}>
              <CircularProgress></CircularProgress>
            </div>
          )
        }
      </div>
    </div>
  )
}