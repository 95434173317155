import React, { useState, useContext } from 'react';

import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from "../../images/SP_logo_color_horizontal.png";

// context
import { AppContext } from "../../Context/AppContextProvide";
import { AdminContext } from "../../Context/AdminContextProvider";

// Images
import { Paper } from '@material-ui/core';
import { BarChart, BugReport, Build, Chat, Close, ExitToApp, Home, Menu, People, Person, PersonalVideo, RecordVoiceOver, Videocam } from '@material-ui/icons';

function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const { user, handleLogout } = useContext(AppContext);
  const { users } = useContext(AdminContext);
  const isAdmin = user?.access_type_cd === "a";

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <div style={{height: "74px"}}></div>
      <span 
        onClick={() => setSidebar(false)}
        style={{display: sidebar ? "block" : "none", zIndex: "4999", position: "fixed", top: "0px", left: "0px", bottom: "0px", right: "0px", backgroundColor: "#36373f", opacity: ".4"}}
      ></span>
      <div style={{position: "fixed", left: "0px", top: "0px", right: "0px", zIndex: "5000"}}>
        <Paper style={{padding: "10px 20px", backgroundColor: "#FFFFFF", textAlign: "right"}}>
          { sidebar ? (
              <Close fontSize="large" style={{color: "#36373f", margin: "9px 0px", float: "left", cursor: "pointer"}} onClick={() => setSidebar(false)}/>
            ) : (
              <Menu fontSize="large" style={{color: "#36373f", margin: "9px 0px", float: "left", cursor: "pointer"}} onClick={() => setSidebar(true)}/>
            )
          }
          <img src={logo} style={{height: "50px"}}/>
        </Paper>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items' onClick={showSidebar}>
            {user?.user_id ? (
              <li className='nav-text'>
                  <Link to='/home'>
                      <span>{"HOME"}</span>
                  </Link>
                  <span style={{marginRight:40}}><Home fontSize="large" style={{color: "#FFFFFF"}}></Home></span>
              </li>
            ) : undefined}               
            {user?.user_id ? (
                <li className='nav-text'>
                <Link to='/sitestatus'>
                  <span>{`SITE STATUS`}</span>
                </Link>
                <span style={{marginRight:40}}>
                  <BarChart fontSize="large" style={{color: "#FFFFFF"}}></BarChart>
                </span>
              </li>
            ) : undefined}
            {isAdmin ? (
                <li className='nav-text'>
                <Link to='/newsite'>
                  <span>{"NEW SITE"}</span>
                </Link>
                <span style={{marginRight:40}}><PersonalVideo fontSize="large" style={{color: "#FFFFFF"}}></PersonalVideo></span>
              </li>
            ) : undefined}
            {isAdmin ? (
                <li className='nav-text'>
                <Link to='/b2b/logs'>
                  <span>{"B2B LOGS"}</span>
                </Link>
                <span style={{marginRight:40}}><BugReport fontSize="large" style={{color: "#FFFFFF"}}></BugReport></span>
              </li>
            ) : undefined}
            {isAdmin ? (
                <li className='nav-text'>
                <Link to='/leads'>
                  <span>{"LEADS"}</span>
                </Link>
                <span style={{marginRight:40}}><RecordVoiceOver fontSize="large" style={{color: "#FFFFFF"}}></RecordVoiceOver></span>
              </li>
            ) : undefined}
            {isAdmin ? (
                <li className='nav-text'>
                <Link to='/videos'>
                  <span>{"VIDEOS"}</span>
                </Link>
                <span style={{marginRight:40}}><Videocam fontSize="large" style={{color: "#FFFFFF"}}></Videocam></span>
              </li>
            ) : undefined}
            {isAdmin ? (
                <li className='nav-text'>
                <Link to='/announcements'>
                  <span>{"ANNOUNCEMENTS"}</span>
                </Link>
                <span style={{marginRight:40}}><Chat fontSize="large" style={{color: "#FFFFFF"}}></Chat></span>
              </li>
            ) : undefined}
            {isAdmin ? (
                <li className='nav-text'>
                  <Link to= {{pathname:"/users", users:users}}>
                    <span>{"USERS"}</span>
                  </Link>
                  <span style={{marginRight:40}}><People fontSize="large" style={{color: "#FFFFFF"}}></People></span>
                </li>
              ) : undefined
            }
            {isAdmin ? (
                <li className='nav-text'>
                  <Link to='/servertools'>
                    <span>{"SERVER TOOLS"}</span>
                  </Link>
                  <span style={{marginRight:40}}><Build fontSize="large" style={{color: "#FFFFFF"}}></Build></span>
                </li>
              ) : undefined
            }
            {user?.user_id ? (
              <li className='nav-text'>
                <Link to= {'/user'}>
                  <span>MY ACCOUNT</span>
                </Link>
                <span style={{marginRight:40}}><Person fontSize="large" style={{color: "#FFFFFF"}}></Person></span>
              </li>
            ) : undefined}
            { user?.user_id ? (
                <li className='nav-text'>
                    <a onClick={() => handleLogout()} style={{cursor: "pointer"}}>
                    <span>{"LOGOUT"}</span>
                    </a>
                    <span style={{marginRight:40}}><ExitToApp fontSize="large" style={{color: "#FFFFFF"}}></ExitToApp></span>
                </li>) : (
                <li className='nav-text'>
                    <Link to='/signin'>
                    <span>{"LOGIN"}</span>
                    </Link>
                    <span style={{marginRight:40}}><ExitToApp fontSize="large" style={{color: "#FFFFFF", transform: "rotate(180deg)"}}></ExitToApp></span>
                </li>
            )}
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Navbar;