import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../Context/AppContextProvide";

// styles
import "../styles/User.css";

// MUI
import Paper from "@material-ui/core/Paper";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import ChangePassword from "./ChangePassword";

const User = (props) => {
  const { user: userData } = useContext(AppContext);
  const [hasImage, setHasImage] = useState(false);
  const [hasUsername, setHasUsername] = useState(false);
  const [open] = useState(false);

  // user data
  const [user, setUser] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (userData.user_id) {
      const tempHasImage = userData.picture !== null;
      const tempUsername = userData.name !== null;
      const name = userData.name === null ? "" : userData.name;
      setHasImage(tempHasImage);
      setHasUsername(tempUsername);
      setUser(name);
      setUsername(userData.user_name);
    }
  }, [userData]);

  const handleNameChange = (e) => setUser(e.target.name);
  const handleSubmit = () => {
    console.log(user);
  };

  const renderAvatar = (hasimage) => {
    return hasimage ? (
      <Avatar className="user__avatar" src={userData.picture}></Avatar>
    ) : (
      <Avatar
        style={{ backgroundColor: `#${Math.floor(Math.random() * 1000000)}` }}
        className="user__avatar"
      >
        {userData.user_name[0].toUpperCase()}
      </Avatar>
    );
  };

  const renderUsername = (hasusername) => {
    return hasusername ? (
      <div>
        <Card elevation={0} className="user__username-container">
          <CardContent>
            <Typography
              className="user_username-label"
              color="textSecondary"
              gutterBottom
            >
              Name
            </Typography>
            <Typography className="user__username" variant="h5" component="h2">
              {userData.name}
            </Typography>
          </CardContent>
        </Card>
      </div>
    ) : (
      <div>
        <Card elevation={0} className="user__username-container">
          <CardContent>
            <Typography
              className="user_username-label"
              color="textSecondary"
              gutterBottom
            >
              Username
            </Typography>
            <Typography className="user__username" variant="h5" component="h2">
              {userData.user_name}
            </Typography>
          </CardContent>
        </Card>
      </div>
    );
  };

  const renderUserData = (data) => {
    return data.user_id ? (
      <div className="user__user-data">
        <table>
          <tbody>
            <tr className="user-info-table">
              <td>User</td>
              <td>
              {user}
                {/* <TextField
                  name="name"
                  type="text"
                  label="Full Name"
                  placeholder="Update Name"
                  value={user}
                  fullWidth
                  onChange={handleNameChange}
                /> */}
              </td>
            </tr>

            <tr id="user-info-table"> 
              <td>Username</td>
              <td>
                {username}
                {/* <TextField
                  name="username"
                  type="text"
                  label="User Name"
                  placeholder="Update Price"
                  value={username}
                  fullWidth
                  onChange={handleUsernameChange}
                /> */}
              </td>
            </tr>

            <tr id="user-info-table">
              <td>Picture</td>
              <td>No</td>
            </tr>

            <tr id="user-info-table">
              <td>User Id</td>
              <td>{userData.user_id}</td>
            </tr>

            {userData.auth_type === "local" && (
              <tr id="user-info-table">
                <td>Change Password</td>
                <td>
                  <ChangePassword open={open} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="user__btn-container">
          {userData.auth_type === "local" && (
            <Button
              disabled={true}
              className="user__update-btn"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    ) : (
      <div>User Data Not available</div>
    );
  };

  const renderProfile = (data) => {
    return userData.user_id ? (
      <div>
        <Paper className="user__acount">
          <div className="user__account-container">
            <div className="user__avatar-container">
              {renderAvatar(hasImage)}
            </div>
            <div className="user__account-info-container">
              <div className="user__account-username">
                {renderUsername(hasUsername)}
              </div>
            </div>
          </div>
        </Paper>
        <Paper className="user_account-data">{renderUserData(userData)}</Paper>
      </div>
    ) : (
      <div>no user</div>
    );
  };
  return <div>{renderProfile(userData)}</div>;
};

export default User;
