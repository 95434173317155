import React, { useContext, useEffect, useRef, useState } from "react";
import { AlertContext } from "../../Context/AlertContextProvider";
import { AppContext } from "../../Context/AppContextProvide";
import Quill from "quill";
import { Button, Grid, Typography } from "@material-ui/core";
import { addNoteToLead, deleteNoteById, updateNoteById } from "../../Actions";
import { convertSYSDATEToUTC } from "../../Util/utilsFunctions";
import "quill/dist/quill.snow.css";
import "./quill.css";

export function NewNote(props) {
  const CHARACTER_LIMIT = 1500;
  const [isFocused, setIsFocused] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [charCount, setCharCount] = useState(0);

  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);

  const editorRef = useRef(null);
  const containerRef = useRef(null);
  const toolbarRef = useRef(null);
  
  useEffect(() => {
    const options = {
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'indent': '-1'}, { 'indent': '+1' }], 
          [{ 'align': ['', 'center', 'right'] }]
        ]
      },
      placeholder: 'Add a note...',
      theme: 'snow'
    };

    editorRef.current = new Quill(containerRef.current, options);
    editorRef.current.on('text-change', () => {
      const noteText = editorRef.current?.getText();

      setCharCount(noteText.length === 1 ? 0 : noteText.length);
      setCanSubmit(noteText.replace(/\r?\n|\r/g, "").length);
    });
    toolbarRef.current = containerRef.current.previousSibling;

    if (isFocused) {
      toolbarRef.current.style.display = "block";
      containerRef.current.style.borderTop = "0px";
    } else {
      toolbarRef.current.style.display = "none";
      containerRef.current.style.borderTop = window.getComputedStyle(containerRef.current).getPropertyValue("border-bottom");
    }

    return () => {
      editorRef.current = null;
      if (containerRef.current) containerRef.current.innerHtml = '';
      if (toolbarRef.current) toolbarRef.current.remove();
    }

  }, [isFocused])

  const clearNote = () => {
    setIsFocused(false);
    editorRef.current?.setContents(undefined);
  }

  const submitNote = async (leadId, delta) => {
    await addNoteToLead(leadId, delta)
      .then(result => {
        setMessage("Successfully added note!");
        setMessageType("success");
        handleAlertOpen();
        clearNote();

        props.refreshData();
      }).catch(err => {
        setMessage("Error adding note to this lead");
        setMessageType("error");
        handleAlertOpen();
      });
  }
  
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div 
          ref={containerRef} 
          onFocus={() => setIsFocused(true)} 
          style={{height: isFocused ? "150px" : "auto"}}
        >
        </div>
        { isFocused ? (
            <Typography 
              variant="caption" 
              style={{display: "block", textAlign: "right", marginTop: "5px"}}
            >
              {charCount} / {CHARACTER_LIMIT} Characters
            </Typography>
          ) : undefined 
        }
      </Grid>
      { isFocused ? (
          <Grid item xs={12} style={{textAlign: "right"}}>
            <Button 
              size="small"
              variant="contained" 
              style={{marginRight: "20px"}}
              onClick={() => clearNote()}
            >
              Cancel
            </Button>
            <Button 
              size="small"
              variant="contained" 
              style={{backgroundColor: "#4caf50", color: "#FFFFFF"}}
              onClick={() => submitNote(props.leadId, editorRef.current?.getContents())}
              disabled={!canSubmit}
            >
              Save
            </Button>
          </Grid>
        ) : undefined
      }  
    </Grid>
  )
}

export function NoteItem(props) {
  const CHARACTER_LIMIT = 1500;
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [canSubmit, setCanSubmit] = useState(false);
  const [isDefaultSet, setIsDefaultSet] = useState(false);
  const [charCount, setCharCount] = useState(0);

  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);
  const { user } = useContext(AppContext);

  const editorRef = useRef(null);
  const containerRef = useRef(null);
  const toolbarRef = useRef(null);

  const checkText = () => {
    const noteText = editorRef.current?.getText();

    setCharCount(noteText.length === 1 ? 0 : noteText.length);
    setCanSubmit(noteText.replace(/\r?\n|\r/g, "").length);
  }
  
  useEffect(() => {
    const options = {
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'indent': '-1'}, { 'indent': '+1' }], 
          [{ 'align': ['', 'center', 'right'] }]
        ]
      },
      theme: 'snow'
    };

    editorRef.current = new Quill(containerRef.current, options);

    if (!isDefaultSet) {
      editorRef.current?.setContents(JSON.parse(props.note.note));
      setIsDefaultSet(true)
    }

    editorRef.current?.on('text-change', () => checkText());
    
    toolbarRef.current = containerRef.current.previousSibling;

    if (!isReadOnly) {
      editorRef.current?.enable();
      checkText();
      toolbarRef.current.style.display = "block";
      containerRef.current.style.borderTop = "0px";
    } else {
      editorRef.current?.disable();
      toolbarRef.current.style.display = "none";
      containerRef.current.style.borderTop = window.getComputedStyle(containerRef.current).getPropertyValue("border-bottom");
    }

    return () => {
      editorRef.current = null;
      if (containerRef.current) containerRef.current.innerHtml = '';
      if (toolbarRef.current) toolbarRef.current.remove();
    }

  }, [isReadOnly])

  const cancelEdit = () => {
    setIsReadOnly(true);
    editorRef.current?.setContents(JSON.parse(props.note.note));
  }

  const updateNote = async (leadId, noteId, delta) => {
    await updateNoteById(leadId, noteId, delta)
      .then(result => {
        setMessage("Successfully updated note!");
        setMessageType("success");
        handleAlertOpen();

        setIsReadOnly(true);
        props.refreshData();
      }).catch(err => {
        console.log(err)
        setMessage("Error updating note");
        setMessageType("error");
        handleAlertOpen();
      });
  }

  const deleteNote = async (leadId, noteId) => {
    await deleteNoteById(leadId, noteId)
      .then(result => {
        setMessage("Successfully deleted note!");
        setMessageType("success");
        handleAlertOpen();

        props.refreshData();
      }).catch(err => {
        setMessage("Error deleting note");
        setMessageType("error");
        handleAlertOpen();
      });
  }
  
  return (
    <Grid container spacing={1} style={{marginTop: "15px", marginBottom: "15px"}}>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          <b>{props.user?.name}</b> added note
        </Typography>
        <Typography variant="subtitle2">
          {new Date(convertSYSDATEToUTC(props.note.created_dttm)).toLocaleString()}
          {props.note.created_dttm !== props.note.last_modified_dttm ? " (Edited)" : ""}
        </Typography> 
      </Grid>
      
      <Grid item xs={12}>
        <div 
          ref={containerRef}  
          style={{height: "auto", maxHeight: "600px", overflowY: "auto"}}
        >
        </div>
        { !isReadOnly ? (
            <Typography 
              variant="caption" 
              style={{display: "block", textAlign: "right", marginTop: "5px"}}
            >
              {charCount} / {CHARACTER_LIMIT} Characters
            </Typography>
          ) : undefined 
        }
      </Grid>
      { !isReadOnly ? (
          <Grid item xs={12} style={{textAlign: "right"}}>
            <Button 
              size="small"
              variant="contained" 
              style={{marginRight: "15px"}}
              onClick={() => cancelEdit()}
            >
              Cancel
            </Button>
            <Button 
              size="small"
              variant="contained" 
              style={{backgroundColor: "#4caf50", color: "#FFFFFF"}}
              onClick={() => updateNote(props.note.lead_id, props.note.lead_note_id, editorRef.current?.getContents())}
              disabled={!canSubmit}
            >
              Update
            </Button>
          </Grid>
        ) : undefined }
      { isReadOnly && props.note.created_by === user.user_id ? (
          <Grid item xs={12} style={{textAlign: "right"}}>
            <Button 
              size="small"
              variant="contained"
              onClick={() => deleteNote(props.note.lead_id, props.note.lead_note_id)}
              style={{marginRight: "15px", backgroundColor: "#f44336", color: "#FFFFFF"}}
            >
              Delete
            </Button>
            <Button 
              size="small"
              variant="contained"
              style={{backgroundColor: "#142e3e", color: "#28c4fc"}}
              onClick={() => setIsReadOnly(false)}
            >
              Edit
            </Button>
          </Grid>
        ) : undefined
      }  
    </Grid>
  )
}