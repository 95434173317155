import React, { useState, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";

import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// Actions
import { uploadLogo as uploadLogoAction } from "../../../Actions";

// icons
import SearchIcon from "@material-ui/icons/Search";

// styles
import "../../styles/Admin.css";

// dependends
import EditUser from "./EditUser";
import CreateUser from "./CreateUser";
import { AlertContext } from "../../../Context/AlertContextProvider";
import { CompanyLogoContext } from "../../../Context/CompanyLogoContextProvider";
import { AdminContext } from "../../../Context/AdminContextProvider";

const ManageUsers = () => {
  let props = useLocation();

  const { users } = useContext(AdminContext);
  // const [users, setUsers] = useState(props.users);
  const [apiUsers] = useState(props.users);
  const [editUser, setEditUser] = useState({});
  const [value, setValue] = useState("");
  const [open] = useState(false);
  const [file, setFile] = useState();

  const { handleAlertOpen, setMessageType, setMessage } = useContext(
    AlertContext
  );

  const { setUpdate } = useContext(CompanyLogoContext);

  const hiddenFileInput = useRef(null);

  const handleFileUpload = (event) => {
    let pickedFile;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);
    }
  };

  const handleUpload = () => {
    hiddenFileInput.current.click();
  };

  const renderUsers = (users) => {
    return users ? (
      users.map((user) => {
        return (
          <ListItem onClick={() => handleUser(user)} key={user.userId} button>
            <ListItemIcon>
              <Avatar alt="profile picture" src={user.picture} />
            </ListItemIcon>
            <ListItemText
              primary={user.name}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    {user.userName}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        );
      })
    ) : (
      <div>No Users</div>
    );
  };

  // const handleChange = (filter) => {
  //   setValue(filter);
  //   const filterValue = filter.toLowerCase();
  //   const tempUsers = apiUsers.filter((user) =>
  //     user.userName.toLowerCase().includes(filterValue)
  //   );
  //   setUsers(tempUsers);
  // };

  const handleUser = (user) => {
    setEditUser(user);
  };

  const uploadLogo = () => {
    let formData = new FormData();
    formData.append("image", file);
    uploadLogoAction(formData)
      .then((result) => {
        setMessage("Logo Uploaded Successfully");
        setMessageType("success");
        handleAlertOpen();
        setUpdate(true);
      })
      .catch((err) => {
        setMessage("Failed to upload company logo");
        setMessageType("error");
        handleAlertOpen();
      });
  };

  return (
    <div>
      {/* <Paper className="admin__update-customer-logo">
        <div className="admin__upload-logo">
          <h4 className="upload-logo-text">Company logo:</h4>
          <input
            className="admin__bulletin-image-uploader"
            type="file"
            ref={hiddenFileInput}
            onChange={handleFileUpload}
            accept="image/x-png,image/jpeg"
          ></input>

          <Button
            className="admin__search-logo-btn"
            onClick={handleUpload}
            color="primary"
            autoFocus
          >
            Browse Image
          </Button>

          <Button
            className="admin__upload-logo-btn"
            onClick={uploadLogo}
            color="primary"
            autoFocus
          >
            Upload
          </Button>
        </div>
      </Paper> */}
      <div className="admin___users-content">
        <div className="admin__user-list">
          <Paper>
            <List
              className="admin__users"
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Users
                </ListSubheader>
              }
            >
              <ListItem>
                {/* <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText>
                  <TextField
                    type="text"
                    label="Search User"
                    value={value}
                    onChange={(e) => handleChange(e.target.value)}
                    className="admin__search-users"
                  />
                </ListItemText> */}
                <Typography variant="h6">New User</Typography>
                <CreateUser open={open} />
              </ListItem>
              <div className="admin__users-list">{renderUsers(users)}</div>
            </List>
          </Paper>
        </div>
        <div style={{ width: 10 }}></div>
        <div className="admin__edit-user">
          {editUser.userId && <EditUser user={editUser} />}
        </div>
      </div>
    </div>
  );
};

export default ManageUsers;
