import React, { useState, createContext, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../Contants";

export const CompanyLogoContext = createContext();

export const CompanyLogoContextProvider = ({ children }) => {
  const [companyLogo, setCompanyLogo] = useState("");
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    async function getCompanyLogo() {
      const result = await axios(`${baseUrl}/api/app/company/logo`);
      setCompanyLogo(result.data.companyLogo);
      setUpdate(false);
    }
    getCompanyLogo();
  }, [update]);

  return (
    <CompanyLogoContext.Provider value={{ companyLogo, setUpdate }}>
      {children}
    </CompanyLogoContext.Provider>
  );
};
