import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// components
import Header from "./Components/Layout/Header";
import Navbar from "./Components/Layout/Navbar";
import Auth from "./Components/Auth";

// Auth route

import AuthRoute from "./Util/AuthRoute";
import AdminAuthRoute from "./Util/AdminAuthRoute";

// styles
import "./App.css";

// context
import { AppContextProvider } from "./Context/AppContextProvide";
import { BulletinContextProvider } from "./Context/BulletinContextProvider";
import { AdminContextProvider } from "./Context/AdminContextProvider";
import { AnnouncementsContextProvider } from "./Context/AnnouncementsContextProvider";
import { AlertProvider } from "./Context/AlertContextProvider";
import { UserAnnouncementsContextProvider } from "./Context/UserAnnouncementsContextProvider";
import { UserBulletinContextProvider } from "./Context/UserBulletinContextProvider";

// axios config
// eslint-disable-next-line no-unused-vars
import axiosConfig from "./Util/axiosConfig";
import Home from "./Components/LandingPage/Home";
import Admin from "./Components/Admin/Admin";
import User from "./Components/User/User";
import Signin from "./Components/LandingPage/Signin";
import SiteStatus from "./Components/SiteStatus/SiteStatus";
import SiteInfo from "./Components/SiteInfo/SiteInfo";
import NewSite from "./Components/NewSite/Newsite";
import Videos from  "./Components/Videos/Videos";
import ManageAnnouncements from "./Components/Admin/Announcements/ManageAnnouncements";
import ManageUsers from "./Components/Admin/Users/ManageUsers";
import { CompanyLogoContextProvider } from "./Context/CompanyLogoContextProvider";
import Leads from "./Components/Leads/Leads";
import ManageLead from "./Components/Leads/ManageLead";
import B2BLogging from "./Components/B2B/Logging";
import ServerTools from "./Components/ServerTools/ServerTools";

const App = () => {
  
  return (
    <div className="App">
      <AlertProvider>
        <Router>
          <AppContextProvider>
            <AdminContextProvider>
              <Navbar />
            </AdminContextProvider>
            <div className="app-content">
              <Switch>
                <Route component={() => <Auth />} path="/auth" />
                {/*  */}
                <Route component={() => <Signin />} path="/signin" />
                {/*  */}
                <AuthRoute
                  path="/home"
                  exact
                  component={() => (
                    <UserAnnouncementsContextProvider>
                      <UserBulletinContextProvider>
                        <Home />
                      </UserBulletinContextProvider>
                    </UserAnnouncementsContextProvider>
                  )}
                />

                <AuthRoute
                  path="/user"
                  exact
                  component={() => <User />}
                ></AuthRoute>

                <AdminAuthRoute
                  path="/admin"
                  exact
                  component={() => {
                    return (
                      <BulletinContextProvider>
                        <AdminContextProvider>
                          <AnnouncementsContextProvider>
                            <CompanyLogoContextProvider>
                             <Admin />
                            </CompanyLogoContextProvider>
                          </AnnouncementsContextProvider>
                        </AdminContextProvider>
                      </BulletinContextProvider>
                    );
                  }}
                />

                  <AdminAuthRoute
                  path="/newsite"
                  exact
                  component={() => {
                    return (
                      <BulletinContextProvider>
                        <AdminContextProvider>
                          <AnnouncementsContextProvider>
                            <CompanyLogoContextProvider>
                             <NewSite />
                            </CompanyLogoContextProvider>
                          </AnnouncementsContextProvider>
                        </AdminContextProvider>
                      </BulletinContextProvider>
                    );
                  }}
                />

                  <AdminAuthRoute
                  path="/videos"
                  exact
                  component={() => {
                    return (
                      <BulletinContextProvider>
                        <AdminContextProvider>
                          <AnnouncementsContextProvider>
                            <CompanyLogoContextProvider>
                             <Videos />
                            </CompanyLogoContextProvider>
                          </AnnouncementsContextProvider>
                        </AdminContextProvider>
                      </BulletinContextProvider>
                    );
                  }}
                />    

                  <AdminAuthRoute
                  path="/announcements"
                  exact
                  component={() => {
                    return (
                      <BulletinContextProvider>
                        <AdminContextProvider>
                          <AnnouncementsContextProvider>
                            <CompanyLogoContextProvider>
                             <ManageAnnouncements />
                            </CompanyLogoContextProvider>
                          </AnnouncementsContextProvider>
                        </AdminContextProvider>
                      </BulletinContextProvider>
                    );
                  }}
                />  

                  <AdminAuthRoute
                  path="/users"
                  exact
                  component={() => {
                    return (
                      <BulletinContextProvider>
                        <AdminContextProvider>
                          <AnnouncementsContextProvider>
                            <CompanyLogoContextProvider>
                             <ManageUsers />
                            </CompanyLogoContextProvider>
                          </AnnouncementsContextProvider>
                        </AdminContextProvider>
                      </BulletinContextProvider>
                    );
                  }}
                /> 

                <AuthRoute
                  path="/sitestatus"
                  exact
                  component={() => {
                    return (
                      <BulletinContextProvider>
                        <AdminContextProvider>
                          <AnnouncementsContextProvider>
                            <CompanyLogoContextProvider>
                              <SiteStatus />
                            </CompanyLogoContextProvider>
                          </AnnouncementsContextProvider>
                        </AdminContextProvider>
                      </BulletinContextProvider>
                    );
                  }}
                />

                <AuthRoute
                  path="/siteinfo/:site"
                  exact
                  component={() => {
                    return (
                      <BulletinContextProvider>
                        <AdminContextProvider>
                          <AnnouncementsContextProvider>
                            <CompanyLogoContextProvider>
                              <SiteInfo />
                            </CompanyLogoContextProvider>
                          </AnnouncementsContextProvider>
                        </AdminContextProvider>
                      </BulletinContextProvider>
                    );
                  }}
                />

                <AdminAuthRoute
                  path="/b2b/logs"
                  exact
                  component={() => {
                    return (
                      <BulletinContextProvider>
                        <AdminContextProvider>
                          <AnnouncementsContextProvider>
                            <CompanyLogoContextProvider>
                              <B2BLogging />
                            </CompanyLogoContextProvider>
                          </AnnouncementsContextProvider>
                        </AdminContextProvider>
                      </BulletinContextProvider>
                    );
                  }}
                />     

                <AdminAuthRoute
                  path="/leads"
                  exact
                  component={() => {
                    return (
                      <BulletinContextProvider>
                        <AdminContextProvider>
                          <AnnouncementsContextProvider>
                            <CompanyLogoContextProvider>
                              <Leads />
                            </CompanyLogoContextProvider>
                          </AnnouncementsContextProvider>
                        </AdminContextProvider>
                      </BulletinContextProvider>
                    );
                  }}
                />  

                <AdminAuthRoute
                  path="/lead/:leadId"
                  exact
                  component={() => {
                    return (
                      <BulletinContextProvider>
                        <AdminContextProvider>
                          <AnnouncementsContextProvider>
                            <CompanyLogoContextProvider>
                              <ManageLead />
                            </CompanyLogoContextProvider>
                          </AnnouncementsContextProvider>
                        </AdminContextProvider>
                      </BulletinContextProvider>
                    );
                  }}
                />  

                <AdminAuthRoute
                  path="/servertools"
                  exact
                  component={() => {
                    return (
                      <BulletinContextProvider>
                        <AdminContextProvider>
                          <AnnouncementsContextProvider>
                            <CompanyLogoContextProvider>
                              <ServerTools />
                            </CompanyLogoContextProvider>
                          </AnnouncementsContextProvider>
                        </AdminContextProvider>
                      </BulletinContextProvider>
                    );
                  }}
                /> 
              </Switch>
            </div>
          </AppContextProvider>
        </Router>
      </AlertProvider>
    </div>
  );
};

export default App;
