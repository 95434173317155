import React, { useContext } from "react";

//MUI
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";

// moment
import moment from "moment";

// styles
import "../styles/Home.css";

//
import { UserAnnouncementsContext } from "../../Context/UserAnnouncementsContextProvider";

const Announcements = () => {
  const { announcements } = useContext(UserAnnouncementsContext);

  const renderAnnouncements = (data) => {
    return data && data.length ? (
      <div style={{display: "flex", flexDirection: "row"}}>
        {data.map((ann, index) => {
          const date = moment(ann.effective_start_date).format("MMM Do YYYY");
          return (
            <Paper key={index} className="home__announcement_paper">
              <Card key={ann.announcement_id} className="home__announcement-card">
                <CardHeader
                  className="annoucement-header"
                  title={ann.title === null ? "Announcement!" : ann.title}
                  subheader={"Created: " + date}
                />
                <CardContent className="home__card-content">
                  <Typography variant="body2" color="textSecondary" component="p">
                    {ann.announcement}
                  </Typography>
                </CardContent>
              </Card>
            </Paper>
          );
        })}
      </div>
    ) : (
      <div>No Announcements to display</div>
    );
  };

  return (
    <div>
      {/* <AppBar position="static" className="home__appbar">
        <Toolbar variant="dense">
          <Typography
            className="home__announcement-header"
            variant="h6"
            color="inherit"
          >
            Announcements
          </Typography>
        </Toolbar>
      </AppBar> */}
      <div className="home__announcement-container">
        {renderAnnouncements(announcements)}
      </div>
    </div>
  );
};

export default Announcements;
