import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Grid, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

function EdiLogItem(props) {
  let errorType = "";
  
  if (props.log.status === 'FAIL' && JSON.stringify(props.log.error_msg).includes("530")) {
    errorType = "Bad Credentials";
  } else if (props.log.status === 'FAIL' && JSON.stringify(props.log.error_msg).includes("550")) {
    errorType = "Bad Command";
  } else if (props.log.status === 'FAIL') {
    errorType = "Generic";
  }

  return (
    <div container style={{borderBottom: "1px solid #BDBDBD"}}>
      <div style={{backgroundColor: "#F2F2F2", padding: "5px 15px"}}>
        <pre style={{whiteSpace: "pre-wrap"}}>
          <Typography style={{display: "block", paddingBottom: "20px"}}><b>{props.log.status === 'FAIL' ? "Error" : "Success"}</b> - {props.log.status === 'FAIL' ? errorType : props.log.file_count + " file(s) processed"}</Typography>
          {JSON.stringify(props.log.status === 'FAIL' ? props.log.error_msg : props.log.file_list, null, 2)}
          <Typography variant="inherit" style={{display: "block", paddingTop: "20px"}}>{props.log.created_dttm + " (UTC)"}</Typography>
        </pre>
      </div>
    </div>
  )
}

export default function B2BStats(props) {
  const [logTypeShown, setLogTypeShown] = useState("All");
  const scrollDivRef = useRef(null);

  useEffect(() => {
    if (scrollDivRef?.current) scrollDivRef.current.scrollTop = 0; 
  }, [logTypeShown])

  const transferType = (props.ediLogs[0]?.config?.transfer_type ?? props.partner?.transfer_type ?? "").toUpperCase();
  const successCount = props.ediLogs.filter(log => log.status !== 'FAIL').length;
  const errorCount = props.ediLogs.filter(log => log.status === 'FAIL').length;
  const credentialAlertRegex = /(530)|(error connecting client)/i;
  let isCredentialErrorPresent = false;

  if (props.ediLogs?.length && credentialAlertRegex.test(JSON.stringify(props.partner?.last_run_message))) { 
    isCredentialErrorPresent = props.ediLogs.slice(0, 3).every(log => credentialAlertRegex.test(JSON.stringify(log.error_msg)));
  }

  let b2bStatus;

  if (props.partner?.last_run_status === 'SUCCESS') {
    b2bStatus = (
      <Alert severity="success">
        <AlertTitle>B2B Connection Running Successfully</AlertTitle>
      </Alert>
    );
  } else if (isCredentialErrorPresent) {
    b2bStatus = (
      <Alert severity="error">
        <AlertTitle>3 Consecutive Login Failures</AlertTitle>
        Looks like the login credentials might be bad. Please verify them for this connection and reach out to the customer or vendor for a new set.
      </Alert>
    );
  } else if (successCount === 0 && props.ediLogs?.length) {
    b2bStatus = (
      <Alert severity="error">
        <AlertTitle>B2B Connection Failure</AlertTitle>
        Looks like there has been a connection failure potentially extending up to the past 30 days. Please review the logs to check if the credentials need to be updated or if there is an {transferType} processing error
      </Alert>
    );
  } else if (props.ediLogs?.length) {
    b2bStatus = (
      <Alert severity="error">
        <AlertTitle>B2B Connection Failure</AlertTitle>
        Looks like there is a connection failure. Please review the logs to check if the credentials need to be updated or if there is an {transferType} processing error.
        <br></br>
        <br></br>
        Please note that this might be a temporary issue if you're seeing this outside of normal business hours.
      </Alert>
    );
  } else if (!props.ediLogs?.length) {
    b2bStatus = (
      <Alert severity="info">
        <AlertTitle>No Data</AlertTitle>
        This connection has not been run in the last 30 days.
      </Alert>
    );
  }

  const ediLogItems = props.ediLogs.filter(log => {
    if (logTypeShown === "Error") return log.status === 'FAIL';
    if (logTypeShown === "Success") return log.status !== 'FAIL';

    return true;
  }).map(log => {
    return (
      <EdiLogItem 
        log={log}
        key={log.edi_log_id}
      />
    )
  });

  return (
    <div style={{padding: "0px 20px 20px"}}>
      <Typography 
        variant="h4" 
        style={{marginBottom: "15px"}}
      >
        Processing Stats
      </Typography>
      <div>
        <Grid container spacing={2}>
          <Grid container item spacing={2} xs={12} lg={12} alignContent="flex-start">
            <Grid item xs={12} lg={6}>
              <Typography variant="h6"><b>Connection Status</b></Typography>
              {b2bStatus}
            </Grid>
            
            { props.partner?.last_run_dttm ? (
                <Grid item xs={12} lg={6}>
                  <Typography variant="h6"><b>Lastest Run Data</b></Typography>
                  <div style={{backgroundColor: "#F2F2F2", padding: "5px 15px"}}>
                    <pre style={{whiteSpace: "pre-wrap"}}>
                      <Typography style={{display: "block", paddingBottom: "5px"}}><b>{props.partner.last_run_status === 'FAIL' ? "Error" : "Success"}</b></Typography>
                        { props.partner.last_run_status === 'FAIL' ? (
                            <span style={{display: "block", padding: "15px 0px"}}>
                              {JSON.stringify(props.partner.last_run_message, null, 2)}
                            </span> 
                          ) : undefined
                        }
                      <Typography variant="inherit" style={{display: "block", paddingTop: "5px"}}>{props.partner.last_run_dttm + " (UTC)"}</Typography>
                    </pre>
                  </div>
                </Grid>
              ) : undefined
            }
          </Grid>
          <Grid item xs={12} lg={12}>
            <Typography variant="h6"><b>Logs (Last 30 Days)</b></Typography>
            <Typography>Logs below include records where files were processed or an error occurred. It does not include successful runs where nothing was processed.</Typography>
            <Button variant="contained" style={{display: "block", margin: "10px 0px 0px"}} onClick={() => props.refresh()}>Refresh Logs</Button>
            <ButtonGroup 
              variant="contained"
              style={{margin: "20px 0px"}}
            >
              <Button 
                onClick={() => setLogTypeShown("All")} 
                style={{backgroundColor: logTypeShown === "All" ? "#142e3e" : "", color: logTypeShown === "All" ? "#28c4fc" : ""}}
              >
                All Logs
              </Button>
              <Button 
                onClick={() => setLogTypeShown("Error")} 
                style={{backgroundColor: logTypeShown === "Error" ? "#f44336" : "", color: logTypeShown === "Error" ? "#FFFFFF" : ""}}
              >
                {errorCount} Errors
              </Button>
              <Button 
                onClick={() => setLogTypeShown("Success")}
                style={{backgroundColor: logTypeShown === "Success" ? "#4caf50" : "", color: logTypeShown === "Success" ? "#FFFFFF" : ""}}
              >
                {successCount} Success
              </Button>
            </ButtonGroup>
            { ediLogItems?.length ? ( 
                <div ref={scrollDivRef} style={{maxHeight: "25vh", overflowY: "scroll", border: "1px solid #BDBDBD"}}>
                  {ediLogItems}
                </div>
              ) : (
                <Typography style={{margin: "15px 0px"}}>No logs available in the last 30 days</Typography>
              )
            }
          </Grid>
        </Grid>
      </div>
    </div>
  )
}