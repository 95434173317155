import { Button, Dialog, Grid, Menu, MenuItem, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { getB2bLogsByEdiId } from "../../Actions";
import { ArrowDropDown } from "@material-ui/icons";
import B2BStats from "./B2BStats";
import B2BSettings from "./B2BSettings";
import B2BServerPreview from "./B2BServerPreview";

export default function B2BDataManagementDialog (props) {
  const [ediLogs, setEdiLogs] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [selectedView, setSelectedView] = useState("Settings");
  const menuButtonRef = useRef(null);

  const getEdiLogs = async () => {
    getB2bLogsByEdiId(props.partner.edi_id).then(result => {
      setEdiLogs([...result.data.ediLogs]);
    }).catch(err => {
      setEdiLogs([]);
    })
  }

  useEffect(() => {

    if (props.partner?.edi_id) {
      getEdiLogs();
      setSelectedView("Settings");
    }

  }, [props.partner?.edi_id])

  const partnerName = props.partner?.name ?? "";
  const brandName = props.partner?.brand ?? "";
  let view;

  if (selectedView === "Settings") {
    view = (
      <B2BSettings 
        partner={props.partner} 
        refresh={() => props.refresh()} 
      />
    );
  } else if (selectedView === "Processing Stats") {
    view = (
      <B2BStats
        partner={props.partner}
        ediLogs={ediLogs}
        refresh={() => getEdiLogs()}
      />
    );
  } else if (selectedView === "View Server") {
    view = (
      <B2BServerPreview
        partner={props.partner}
      />
    );
  } else {
    view = (
      <Typography>Unavailable view type selected</Typography>
    );
  }

  return (
    <Dialog 
      open={props.isOpen} 
      onClose={() => {
        props.onClose();
      }}
      maxWidth={false}
    >
      <Paper style={{minWidth: "300px", width: "70vw", maxHeight: "80vh"}}>
        <Typography 
          className="siteinfo__header"
          variant="h4" 
          style={{marginBottom: "10px"}}
        >
          <b>
            {partnerName + " "}
            {brandName ? "(" + brandName + ") " : ""}
            Management
          </b>
        </Typography>
        <div style={{padding: "0px 20px 30px"}}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Typography variant="h6">Menu</Typography>
              <Button aria-controls="b2b-menu" aria-haspopup="true" ref={menuButtonRef} variant="contained" onClick={() => setShowMenu(true)}>
                {selectedView} <ArrowDropDown></ArrowDropDown>
              </Button>
              <Menu 
                id="b2b-menu"
                keepMounted={true} 
                anchorEl={menuButtonRef.current}
                open={showMenu}
                onClose={() => setShowMenu(false)}
              >
                <MenuItem 
                  onClick={() => {
                    setSelectedView("Settings");
                    setShowMenu(false);
                  }}
                >
                  Settings
                </MenuItem>
                <MenuItem 
                  onClick={() => {
                    setSelectedView("Processing Stats");
                    setShowMenu(false);
                  }}
                >
                  Processing Stats
                </MenuItem>
                <MenuItem 
                  onClick={() => {
                    setSelectedView("View Server");
                    setShowMenu(false);
                  }}
                >
                  View Server
                </MenuItem>
              </Menu>
            </Grid>
            <Grid item sm={6} style={{alignSelf: "flex-end", textAlign: "right"}}>
              <Button variant="contained" onClick={() => props.onClose()}>Close B2B Manager</Button>
            </Grid>
          </Grid>
        </div>
        {view}
      </Paper>
    </Dialog>
  )
}