import React, { useState, useEffect, useContext } from "react";

// MUI
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';


// styles
import "../styles/Videos.css";
import { CardActionArea } from "@material-ui/core";

// Actions
import {  getVideoLinks, addNewVideo, deleteVideo } from "../../Actions/";

// Icons
import * as BsIcons from 'react-icons/bs';

// Context 
import { AlertContext } from "../../Context/AlertContextProvider";
import { useParams } from "react-router-dom";

const ImportExport = () => {
  const { handleAlertOpen, setMessageType, setMessage } = useContext(
    AlertContext
  );
  const [open, setOpen] = useState(false);
  const [videosOpen, setVideosOpen] = useState(false);
  var tmpTitle =[];
  var tmpId = [];
  var tmpThumb = [];
  var tmpDescription = [];
  const [videoId, setVideoId] = useState([]);
  const [title, setTitle] = useState([]);
  const [thumbnail, setThumbnail] = useState([]);
  const [description, setDescription] = useState([]);
  const [selectedVidId, setSelectedVidId] = useState();
  const [videoTitle, setVideoTitle] = useState("Video Turorial");
  const [videoUrl, setVideoUrl] = useState("");
  const [newTitle, setNewTitle] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [showProgress, setShowProgress] = useState(false);
  const [videoData, setVideoData] = useState([]);
  const drawerWidth = 240;
  const [update, setUpdate] = useState(false);

  useEffect(
    () => {
      getVideoLinks()
      .then((result) => {
          setVideoData(result.data.data);
        result.data.data.map((data) => {
          tmpId.push(data.video_id);
          tmpTitle.push(data.title);
          tmpDescription.push(data.description);
          tmpThumb.push(data.thumbnail);
        })
      })
      .then(() => {setVideoId(tmpId);
        setTitle(tmpTitle);
        setThumbnail(tmpThumb);
        setDescription(tmpDescription);
      });
    },
    [drawerWidth, update]
  );

  const YoutubeEmbed = ({ embedId }) =>
    <div className="video-responsive">
      <iframe
        width="100%"
        height="100%"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Tutorial"
      />
    </div>;

    const resetVars = () => {
      setNewTitle("");
      setVideoUrl("");
      setNewDescription("");
    };

  const handleOpen = (vId, title) => {
    setSelectedVidId(vId);
    setVideoTitle(title);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setVideosOpen(false);
  };

  const handleVideosOpen = () => {
      setVideosOpen(true);
  };

  const handleUrl = (e) => {
    setVideoUrl(e);
  };

  const handleTitle = (e) => {
    setNewTitle(e);
  };

  const handleDescription = (e) => {
    setNewDescription(e);
  };

  const handleSubmit = () => {
    setShowProgress(true);
    addNewVideo(videoUrl, newTitle, newDescription)
    .then((result) => {
      resetVars();
      setShowProgress(false);

      getVideoLinks()
      .then((result) => {
          setVideoData(result.data.data);
        result.data.data.map((data) => {
          tmpId.push(data.video_id);
          tmpTitle.push(data.title);
          tmpDescription.push(data.description);
          tmpThumb.push(data.thumbnail);
        })
      })
      .then(() => {setVideoId(tmpId);
        setTitle(tmpTitle);
        setThumbnail(tmpThumb);
        setDescription(tmpDescription);
      });
    })
    .then((err) => {
      console.log(err);
    });
  };

  const handleDeleteVideo = (video_id) => {
    setShowProgress(true);
    deleteVideo(video_id)
    .then(() => {
    setMessageType("success");
    setMessage("Successfully Deleted Video");
    handleAlertOpen(true);
    setUpdate(true);
    setShowProgress(false);
    })
    .catch((err) => {
    setMessageType("failed");
    setMessage("Failed to Delete Video");
    handleAlertOpen(true);
    setShowProgress(false);
    });
  };

  const VideoCardData = ({ videoId, thumbnail, description, title }) => {
    return (
      <Card elevation={10} className="card">
        <CardActionArea onClick={() => handleOpen(videoId, title)}>
          <CardMedia component="img" title="File upload" image={thumbnail} />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  const renderVideoList = (results) => {
      var x = 0;
    return (
        <div>
            <Dialog 
            open={videosOpen}
            onClose={handleClose}
            fullwidth="true"
            maxWidth="xl"
            >
              {showProgress && <CircularProgress size={70} />}
                {results.map((data, index) => {
                    x++
                    return(
                        <List  key={index} sx={{ width: '100%', maxWidth: 360, bgcolor: 'background-paper'}}>
                            {/* <a target="blank" className="video-links" href={`https://www.youtube.com/embed/${data.video_id}`}> */}
                                <ListItem>
                                    <Typography variant="h5" style={{marginRight:10}}>
                                        {`${x}.`}
                                    </Typography>
                                    <ListItemAvatar>
                                    <Avatar>
                                        <img src={`https://img.youtube.com/vi/${data.video_id}/1.jpg`} />
                                    </Avatar>
                                    </ListItemAvatar>
                                    <a target="blank" className="video-links" href={`https://www.youtube.com/embed/${data.video_id}`}>
                                       <ListItemText primary={`${data.title} - ${data.description}`} secondary={`created: ${data.created_dttm.split(" ")[0]}`} />
                                    </a>
                                    {/* <Button variant="contained"  onClick={() => handleDeleteVideo(data.video_id)}>
                                      Delete
                                    </Button> */}
                                </ListItem>
                            {/* </a> */}
                            <Divider />
                        </List>
                    )
                })}
        </Dialog>
        </div>
      );
  }

//   const renderVideos = () => {
//     return (
//         <div>
//             <Dialog 
//             open={videosOpen}
//             onClose={handleClose}
//             fullwidth="true"
//             maxWidth="xl"
//             >
//       <Dialog
//         open={open}
//         onClose={handleClose}
//         fullWidth={true}
//         maxWidth="lg"
//         className="video-popup"
//       >
//         <DialogTitle className="video-dialog">
//           <Typography variant={"h5"}>
//             {videoTitle}
//           </Typography>
//         </DialogTitle>
//         <DialogContent>
//           <YoutubeEmbed embedId={selectedVidId} />
//         </DialogContent>
//       </Dialog>
//       <div className="video-container">
//         <Paper className="video-grid-paper" id="video-container" elevation={3}>
//           <Grid container spacing={5} className="video-grid">
//             {thumbnail.map((thumb, index) => {
//               return (
//                 <Grid item xs={12} sm={9} md={6} lg={2}>
//                   <VideoCardData
//                     videoId={videoId[index]}
//                     thumbnail={thumbnail[index]}
//                     description={description[index]}
//                     title={title[index]}
//                   />
//                 </Grid>
//               );
//             })}
//           </Grid>
//         </Paper>
//       </div>
//       </Dialog>
//       </div>
//     );
//   };

  const renderButton = () => {
    return(
        <div>
            <Button className="video-button" variant="contained" onClick={handleVideosOpen}>
                View Videos
            </Button>
        </div>
    )
  };

  return (
    <div className="container">
      <Typography variant="h4">
          Manage videos.
      </Typography>
      <Paper className="contact-form" elevation={5}>
        {showProgress && <CircularProgress size={50} />}
            <h1 className="video-form-text">
              Add a new video <BsIcons.BsCameraVideo  size="35"/>
            </h1>    
            
        <TextField
          margin="dense"
          id="url"
          label="URL"
          type="text"
          variant="filled"
          onChange={(e) => handleUrl(e.target.value)}
          value={videoUrl}
          className="message-text-field"
        />
        
        <TextField
          margin="dense"
          id="title"
          label="TITLE"
          type="text"
          variant="filled"
          onChange={(e) => handleTitle(e.target.value)}
          value={newTitle}
          className="message-text-field"
        />
        
        <TextField
          margin="dense"
          id="description"
          label="DESCRIPTION"
          type="text"
          variant="filled"
          onChange={(e) => handleDescription(e.target.value)}
          value={newDescription}
          className="message-text-field"
        />
        <Button className="contact-button" onClick={handleSubmit} variant={'contained'}>Submit</Button>
        </Paper> 
        <Divider className="divider"/>
        <Typography variant="h4" className="video-count">
            {`There are ${videoId.length} videos`}
        </Typography>
        {renderButton()}
        {renderVideoList(videoData)}
    </div>
  );
};

export default ImportExport;
