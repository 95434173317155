import { Button, Chip, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { addEventToLead, deleteContact, getAffiliateGroupList, getExternalSoftwareList, getLeadById, getLeadStatuses, getLeadUsers, getMarkets, getReferralTypes, getRevenueTiers } from "../../Actions";
import { AccountBalance, Computer, Edit, Group, Hearing, Launch, Store } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { convertSYSDATEToUTC } from "../../Util/utilsFunctions";
import { NewNote, NoteItem } from "./NoteInputs";
import SubscriptionStatus from "./SubscriptionStatus";
import { AddContactDialog, AssignDialog, AttributeDialog, LeadStatusDialog, MarketDialog, UpdateCompanyDialog, UpdateContactDialog } from "./ManageLeadDialogs";
import { AlertContext } from "../../Context/AlertContextProvider";

export default function ManageLead(props) {
  const { leadId } = useParams();
  const [refresh, setRefresh] = useState(true);
  const [lead, setLead] = useState([]);
  const [leadStatuses, setLeadStatuses] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [users, setUsers] = useState([]);
  const [revenueTiers, setRevenueTiers] = useState([]);
  const [referralTypes, setReferralTypes] = useState([]);
  const [affiliateGroups, setAffiliateGroups] = useState([]);
  const [externalSoftwares, setExternalSoftwares] = useState([]);
  const [showAllDetails, setShowAllDetails] = useState(true);
  const [isMarketDialogOpen, setIsMarketDialogOpen] = useState(false);
  const [isAssignDialogOpen, setIsAssignDialogOpen] = useState(false);
  const [isLeadStatusDialogOpen, setIsLeadStatusDialogOpen] = useState(false);
  const [isAttributeDialogOpen, setIsAttributeDialogOpen] = useState(false);
  const [isAddContactDialogOpen, setIsAddContactDialogOpen] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState();
  const [isUpdateContactDialogOpen, setIsUpdateContactDialogOpen] = useState(false);
  const [isUpdateCompanyDialogOpen, setIsUpdateCompanyDialogOpen] = useState(false);

  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);

  const activityContainerRef = useRef(null);

  useEffect(() => {
    if (activityContainerRef.current) activityContainerRef.current.scrollTop = 0;
  }, [showAllDetails])

  useEffect(() => {
    async function getLeadData() {
      await getLeadById(leadId)
        .then(result => {
          const company = result.data.company;
          const site = result.data.site;
          const contacts = result.data.contacts;
          const markets = result.data.company_markets;
          const activity = [...result.data.notes, ...result.data.history];

          setLead({
            ...result.data.lead,
            company,
            site,
            contacts,
            markets,
            activity
          });
        }).catch(err => {
          setLead(undefined);
        });

      await getLeadStatuses()
        .then(result => {
          setLeadStatuses(result.data.data);
        }).catch(err => {
          setLeadStatuses([]);
        });

      await getMarkets()
        .then(result => {
          setMarkets(result.data.data);
        }).catch(err => {
          setMarkets([]);
        });

      await getReferralTypes()
        .then(result => {
          setReferralTypes(result.data.data);
        }).catch(err => {
          setReferralTypes([]);
        });
      
      await getRevenueTiers()
        .then(result => {
          setRevenueTiers(result.data.data);
        }).catch(err => {
          setRevenueTiers([]);
        });

      await getAffiliateGroupList()
        .then(result => {
          setAffiliateGroups(result.data.data);
        }).catch(err => {
          setAffiliateGroups([]);
        });

      await getExternalSoftwareList()
        .then(result => {
          setExternalSoftwares(result.data.data);
        }).catch(err => {
          setExternalSoftwares([]);
        });

      await getLeadUsers()
        .then(result => {
          setUsers(result.data.data);
        }).catch(err => {
          setUsers([]);
        });
    }

    if (refresh) {
      if (activityContainerRef.current) activityContainerRef.current.scrollTop = 0;
    
      getLeadData();
      setRefresh(false);
    }
  }, [refresh])

  const removeContact = async (leadId, contactId, contact) => {
    const isSiteContact = contact.site_id !== null;
    const isOnlyContact = lead.contacts.length === 1;

    if (isSiteContact) {
      setMessage("Error deleting contact lead. Site contacts cannot be deleted");
      setMessageType("error");
      handleAlertOpen();

      return;
    }

    if (isOnlyContact) {
      setMessage("Error deleting contact lead. Cannot remove last contact");
      setMessageType("error");
      handleAlertOpen();

      return;
    }
  
    await deleteContact(leadId, contactId)
      .then(result => {
        setMessage("Successfully deleted lead contact!");
        setMessageType("success");
        handleAlertOpen();

        addEventToLead(leadId, `deleted contact for [${contact.name}] from this lead`);
    
        setRefresh(true);
      }).catch(err => {
        setMessage("Error deleting lead contact");
        setMessageType("error");
        handleAlertOpen();

        return;
      });
  }

  const externalSoftwareName = externalSoftwares.find(s => s.external_software_id === lead.external_software_id)?.name;
  const affiliateGroupName = affiliateGroups.find(g => g.affiliate_group_id === lead.affiliate_group_id)?.name;
  const referralTypeName = referralTypes.find(r => r.referral_type_id === lead.referral_type_id)?.name;
  const revenueTierDescription = revenueTiers.find(r => r.revenue_tier_id === lead.revenue_tier_id)?.description;
  const assignedToName = lead.assigned_to ? users.find(u => u.user_id === lead.assigned_to)?.name : undefined;
  const leadStatusName = leadStatuses.find(s => s.lead_status_id === lead.lead_status_id)?.name;
  const subdomain = lead.site?.site_name.replace(".showroompricing\.com", "");
  let leadStatusSeverity;

  switch (lead.lead_status_id) {
    case 2:
    case 3:
    case 5:
    case 6:
    case 9:
      leadStatusSeverity = "warning";
      break;
    case 7:
    case 8:
      leadStatusSeverity = "success";
      break;
    case 4:
      leadStatusSeverity = "error";
      break;
    default:
      leadStatusSeverity = "info";
  }

  const marketList = lead.markets?.map(market => {
    return (
      <Chip
        key={market.market_id}
        label={market.name}
        style={{backgroundColor: "#142e3e", color: "#28c4fc", border: "0px", margin: "5px 10px 5px 0px"}}
        variant="outlined"
      />
    )
  });

  const contactList = lead.contacts?.map(contact => {
    const isPrimary = lead.contacts.length === 1;
    const isSiteContact = contact.site_id !== null;

    return (
      <div key={contact.contact_id} style={{padding: "15px 0px"}}>
        <Typography variant="h6">
          <b>{contact.name}</b> 
          {isPrimary ? (
              <Chip 
                size="small"
                label={"Primary"}
                style={{backgroundColor: "#4caf50", color: "#FFFFFF", borderColor: "#4caf50", marginLeft: "10px"}}
              />
            ) : undefined
          }
          {isSiteContact ? (
              <Chip 
                size="small"
                label={"Site Contact"}
                style={{backgroundColor: "#142e3e", color: "#28c4fc", borderColor: "#142e3e", marginLeft: "10px"}}
              />
            ) : undefined
          }
        </Typography>
        <Typography>{contact.phone}</Typography>
        <a href={`mailto:${contact.email}`}><Typography>{contact.email}</Typography></a>
        <div style={{marginTop: "10px"}}>
          <Button
            size="small"
            variant="contained"
            onClick={() => removeContact(lead.lead_id, contact.contact_id, contact)}
            style={{marginRight: "15px", backgroundColor: "#f44336", color: "#FFFFFF"}}
          >
            Delete
          </Button>
          <Button 
            size="small"
            variant="contained"
            style={{backgroundColor: "#142e3e", color: "#28c4fc"}}
            onClick={() => {
              setSelectedContactId(contact.contact_id);
              setIsUpdateContactDialogOpen(true);
            }}
          >
            Edit
          </Button>
        </div>
      </div>
    )
  });

  const activityList = lead.activity?.filter(activity => {
    if (!showAllDetails) return !!activity.lead_note_id;

    return true;
  }).sort((a, b) => {
    return new Date(b.created_dttm).getTime() - new Date(a.created_dttm).getTime();
  }).map(activity => {
    const user = users.find(user => user.user_id === activity.created_by);

    if (!activity.lead_note_id) {
      const descriptionArr = activity.description.split(/[\[\]]/g);
      const descriptionContentArr = descriptionArr.map((i, index) => {
        if (index % 2 !== 0) {
          return <React.Fragment key={activity.lead_note_id + '-' + index}><b>{i}</b></React.Fragment>;
        }

        return <React.Fragment key={activity.lead_note_id + '-' + index}>{i}</React.Fragment>;
      })

      return (
        <Grid item xs={12} key={"history-" + activity.lead_history_id} style={{marginTop: "15px", marginBottom: "15px"}}>
          <Typography variant="subtitle2">
            <b>{user?.name}</b> {descriptionContentArr}
          </Typography>
          <Typography variant="subtitle2">
            {new Date(convertSYSDATEToUTC(activity.created_dttm)).toLocaleString()}
          </Typography> 
        </Grid>
      )
    } 

    return (
      <NoteItem 
        key={"note-" + activity.lead_note_id} 
        note={activity} 
        leadId={leadId} 
        user={user}
        refreshData={() => setRefresh(true)}
      />
    )

  });

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper elevation={5}>
            <div className="siteinfo__header">
              <Typography variant="h4">
                Company Info
              </Typography>
            </div>
            <div style={{padding: "20px 10px 30px"}}>
              <Typography variant="h5"><b>{lead.company?.company}</b></Typography>
              { lead.company?.website_url ? (
                  <Typography>
                    <a href={`${!lead.company?.website_url.includes("http") ? "http://" + lead.company?.website_url : lead.company?.website_url}`} target="_blank" style={{ textDecoration: "none", cursor: "pointer", color: "#838383"}}>
                      <Launch style={{display: "inline-block", marginRight: "3px", verticalAlign: "middle"}}></Launch> {lead.company?.website_url}
                    </a>
                  </Typography>
                ) : undefined
              }
              <Typography style={{marginTop: "10px"}}>{lead.company?.address}</Typography>
              <Typography>{lead.company?.city}, {lead.company?.state} {lead.company?.zip}</Typography>
              { lead.company?.country ? (
                  <Typography>{lead.company?.country}</Typography>
                ) : undefined
              }

              <Button variant="contained" onClick={() => setIsUpdateCompanyDialogOpen(true)} style={{margin: "20px 0px 10px"}}>
                Edit Company
              </Button>

              <Typography variant="h6" style={{marginTop: "20px"}}><b>Site Data</b></Typography>
              { !lead.site ? (
                  <Typography>Site not available</Typography>
                ) : undefined
              }
              { lead.site ? (
                  <>
                    <Typography>
                      <a href={`https://${lead.site?.site_name}`} target="_blank" style={{ textDecoration: "none", cursor: "pointer", color: "#838383"}}>
                        <Launch style={{display: "inline-block", marginRight: "8px", verticalAlign: "middle"}}></Launch>
                        {"https://" + lead.site?.site_name}
                      </a>
                    </Typography>
                    <SubscriptionStatus 
                      subscriptionStatus={lead.site?.subscription_status}
                      subscriptionEndDate={lead.site?.subscription_end_dttm}
                    />
                    <Typography style={{marginTop: "10px"}}><b>Created Date</b></Typography>
                    <Typography>{new Date(convertSYSDATEToUTC(lead.site?.created_dttm)).toLocaleDateString()}</Typography>
                    <Typography style={{marginTop: "10px"}}><b>Database Instance</b></Typography>
                    <Typography>{lead.site?.db_instance}</Typography>

                    <Button variant="contained" href={`/siteinfo/${subdomain}`} target="_blank" style={{margin: "20px 0px 0px"}}>
                      Manage Site
                    </Button>
                  </>
                ) : undefined
              }  
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={5}>
            <div className="siteinfo__header">
              <Typography variant="h4">
                Lead Data
              </Typography>
            </div>
            <div style={{padding: "20px 10px 30px"}}>
              <Typography><b>Created Date</b></Typography>
              <Typography>{new Date(convertSYSDATEToUTC(lead.created_dttm)).toLocaleDateString()}</Typography>

              <Typography style={{marginTop: "15px"}}><b>Last Modified Date</b></Typography>
              <Typography>{new Date(convertSYSDATEToUTC(lead.last_modified_dttm)).toLocaleDateString()}</Typography>
              
              <Typography style={{marginTop: "15px"}}>
                <b>Assigned To</b>
                <IconButton 
                  size="small" 
                  onClick={() => setIsAssignDialogOpen(true)}
                  style={{display: "inline-block", verticalAlign: "top", cursor: "pointer", marginLeft: "6px"}}
                >
                  <Edit fontSize="inherit"/>
                </IconButton>
              </Typography>
              <Typography>{assignedToName ?? "Unassigned"}</Typography>

              <Typography style={{marginTop: "15px"}}>
                <b>Lead Status</b> 
                <IconButton 
                  size="small" 
                  onClick={() => setIsLeadStatusDialogOpen(true)}
                  style={{display: "inline-block", verticalAlign: "top", cursor: "pointer", marginLeft: "6px"}}
                >
                  <Edit fontSize="inherit"/>
                </IconButton>
              </Typography>
              <Alert severity={leadStatusSeverity} style={{marginTop: "5px", maxWidth: "250px"}}>{leadStatusName}</Alert>

              <Typography style={{marginTop: "20px"}}>
                <b>Markets Served</b>
                <IconButton 
                  size="small" 
                  onClick={() => setIsMarketDialogOpen(true)}
                  style={{display: "inline-block", verticalAlign: "top", cursor: "pointer", marginLeft: "6px"}}
                >
                  <Edit fontSize="inherit"/>
                </IconButton>
              </Typography>
              <div>
                {marketList}
              </div>
              
              <Typography style={{marginTop: "15px", marginBottom: "8px"}}>
                <b>Additional Attributes</b>
                <IconButton 
                  size="small" 
                  onClick={() => setIsAttributeDialogOpen(true)}
                  style={{display: "inline-block", verticalAlign: "top", cursor: "pointer", marginLeft: "6px"}}
                >
                  <Edit fontSize="inherit"/>
                </IconButton>
              </Typography>
              <Grid container spacing={3}>
                { lead.revenue_tier_id ? (
                    <Grid item>
                      <Typography>
                        <AccountBalance fontSize="small" style={{display: "inline-block", verticalAlign: "top", marginRight: "5px"}}/>
                        {revenueTierDescription}
                      </Typography>
                    </Grid>
                  ) : undefined
                }
                { lead.location_count ? (
                    <Grid item>
                      <Typography>
                        <Store fontSize="small" style={{display: "inline-block", verticalAlign: "top", marginRight: "5px"}}/>
                        {lead.location_count + " Location" + (lead.location_count > 1 ? "s" : "")}
                      </Typography>
                    </Grid>
                  ) : undefined
                }
                { lead.external_software_id ? (
                    <Grid item>
                      <Typography>
                        <Computer fontSize="small" style={{display: "inline-block", verticalAlign: "top", marginRight: "5px"}}/>
                        {externalSoftwareName}
                      </Typography>
                    </Grid>
                  ) : undefined
                }
                { lead.affiliate_group_id ? (
                    <Grid item>
                      <Typography>
                        <Group fontSize="small" style={{display: "inline-block", verticalAlign: "top", marginRight: "5px"}}/>
                        {affiliateGroupName}
                      </Typography>
                    </Grid>
                  ) : undefined
                }
                { lead.referral_type_id ? (
                    <Grid item>
                      <Typography>
                        <Hearing fontSize="small" style={{display: "inline-block", verticalAlign: "top", marginRight: "5px"}}/>
                        {"Referred From " + referralTypeName}
                      </Typography>
                    </Grid>
                  ) : undefined
                }
              </Grid>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={5}>
            <div className="siteinfo__header">
              <Typography variant="h4">
                Contacts
              </Typography>
            </div>
            <div style={{padding: "20px 10px 10px"}}>
              <Button variant="contained" onClick={() => setIsAddContactDialogOpen(true)}>Add Contact</Button>
              <div style={{maxHeight: "600px", overflowY: "auto", marginTop: "20px", border: "1px solid #ccc", padding: "10px 10px 30px"}}>
                {contactList}
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={5}>
            <div className="siteinfo__header">
              <Typography variant="h4">
                Activity/Notes
              </Typography>
            </div>
            <div style={{padding: "20px 10px"}}>
              <NewNote 
                leadId={leadId}
                refreshData={() => setRefresh(true)}
              />
              <Grid container spacing={2} style={{padding: "20px 0px 0px"}}>
                <Grid item xs={12} style={{textAlign: "right"}}>
                  <Button size="small" variant="contained" onClick={() => setShowAllDetails(!showAllDetails)}>{showAllDetails ? "Hide" : "Show"} Details</Button>
                </Grid>
                <Grid item xs={12}>
                  <div 
                    ref={activityContainerRef}
                    style={{
                      overflowY: "auto", 
                      maxHeight: "900px", 
                      border: "1px solid #ccc",
                      padding: "0px 10px 30px" 
                    }}
                  >
                    {activityList}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <MarketDialog 
        isOpen={isMarketDialogOpen} 
        onClose={() => setIsMarketDialogOpen(false)}
        markets={markets} 
        lead={lead} 
        refreshData={() => setRefresh(true)}
      />
      <AssignDialog 
        isOpen={isAssignDialogOpen} 
        onClose={() => setIsAssignDialogOpen(false)} 
        users={users} 
        lead={lead} 
        refreshData={() => setRefresh(true)}
      />
      <LeadStatusDialog 
        isOpen={isLeadStatusDialogOpen} 
        onClose={() => setIsLeadStatusDialogOpen(false)} 
        statuses={leadStatuses} 
        lead={lead} 
        refreshData={() => setRefresh(true)}
      />
      <AttributeDialog 
        isOpen={isAttributeDialogOpen} 
        onClose={() => setIsAttributeDialogOpen(false)} 
        revenueTiers={revenueTiers}
        affiliateGroups={affiliateGroups}
        referralTypes={referralTypes}
        externalSoftwares={externalSoftwares} 
        lead={lead} 
        refreshData={() => setRefresh(true)}
      />
      <AddContactDialog 
        isOpen={isAddContactDialogOpen} 
        onClose={() => setIsAddContactDialogOpen(false)}  
        lead={lead} 
        refreshData={() => setRefresh(true)}
      />
      <UpdateContactDialog
        isOpen={isUpdateContactDialogOpen} 
        onClose={() => {
          setSelectedContactId(undefined);
          setIsUpdateContactDialogOpen(false);
        }}  
        contactId={selectedContactId}
        lead={lead} 
        refreshData={() => setRefresh(true)}
      />
      <UpdateCompanyDialog
        isOpen={isUpdateCompanyDialogOpen} 
        onClose={() => setIsUpdateCompanyDialogOpen(false)}  
        lead={lead} 
        refreshData={() => setRefresh(true)}
      />
    </div>
  )
}