import React, { useEffect, useState } from "react";

//MUI
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import Tooltip from "@material-ui/core/Tooltip";
import { Autorenew, Launch } from "@material-ui/icons";

//Styles
import "../styles/SiteStatus.css";

//Actions
import { startStop } from "../../Actions/index";
import { convertSYSDATEToUTC } from "../../Util/utilsFunctions";

export default function SiteStatusItem (props) {

  const handleClick = async (id, ss) => {
    startStop(id, ss).then(result => {
      if (result.status === 200) {
        props.refresh();
      }
    });
  };

  const renderButton = data => {
    return data.status == "running" ? (
        <Button
          variant="contained"
          style={{ backgroundColor: "#f44336", color: "#FFFFFF", margin: "25px auto 0px"}}
          onClick={() => handleClick(data.instanceId, "STOP")}
        >
          Stop
        </Button>
      ) : (
        data.status == "terminated"
        ? undefined
        : <Button
          variant="contained"
          style={{ backgroundColor: "#4caf50", color: "#FFFFFF", margin: "25px auto 0px"}}
          onClick={() => handleClick(data.instanceId, "START")}
        >
          Start
        </Button>
      );
  };

  function SubscriptionStatus (props) {
    let severity;
    let verbiage;

    switch (props.subscriptionStatus) {
      case 0:
        severity = "success";
        verbiage = "Subscription active";
        break;
      case 1:
        const endDate = new Date(props.subscriptionEndDate?.split(" ")[0]);
        const currentDate = new Date();
        const retentionDate = new Date(endDate);
        retentionDate.setMonth(endDate.getMonth() + 2);
        severity = endDate.getTime() > currentDate.getTime() ? "warning" : "error";

        if (endDate.getTime() > currentDate.getTime()) {
          verbiage = "Subscription ending on " + endDate.toLocaleDateString();
        } else if (endDate.getTime() <= currentDate.getTime() && currentDate.getTime() <= retentionDate.getTime()) {
          verbiage = "Subscription in grace period until " + retentionDate.toLocaleDateString();
        } else {
          verbiage = "Subscription deactivated";
        }
        
        break;
      case 2:
        severity = "warning";
        verbiage = "Subscription paused";
        break;
      default:
        severity = "info";
        verbiage = "No status";
    }

    return (
      <Alert severity={severity}>
        {verbiage}
      </Alert>
    )
  }

  const site = props.site;
  const ec2Data = props.ec2Data;
  const subdomain = site.site_name.replace(".showroompricing\.com", "");

  let siteStatus;

  switch (ec2Data?.status) {
    case "running":
      siteStatus = (
        <Alert severity="success">
          EC2 {ec2Data?.status}
        </Alert>
      )
      break;
    case "pending":
    case "stopping":
      siteStatus = (
        <Alert severity="warning">
          EC2 {ec2Data?.status}
        </Alert>
      )
      break;
    case "shutting-down":
      siteStatus = (
        <Alert severity="error">
          EC2 {ec2Data?.status}
        </Alert>
      )
      break;
    default: 
      if (ec2Data) {
        siteStatus = (
          <Alert severity="error">
            EC2 {ec2Data?.status}
          </Alert>
        )
      } else {
        siteStatus = undefined
      }
  }

  let appVersion;

  if (ec2Data?.apiStatus.version === "unknown") {
    appVersion = ec2Data?.apiStatus.version;
  } else if (ec2Data?.apiStatus.version) {
    appVersion = `v${ec2Data?.apiStatus.version}`
  }

  return (
    <div style={{borderBottom: "1px solid #838383", textAlign: "left", padding: "10px 10px 25px", display: !props.isVisible ? "none" : "block"}}>
      <div style={{display: "inline-block", verticalAlign: "top", width: "75%"}}>
        <Typography variant="h5" style={{fontWeight: "bold", padding: "0px 0px 5px"}}>
          {site.company_name}
        </Typography>
        <Typography>
          <a href={`https://${site.site_name}`} target="_blank" style={{ textDecoration: "none", cursor: "pointer", color: "#838383"}}>
            <Launch style={{marginRight: "3px", verticalAlign: "middle"}}></Launch> {site?.site_name}
          </a>
        </Typography>
        <div style={{margin: "15px 0px 10px", maxWidth: "300px"}}>
          <SubscriptionStatus 
            subscriptionStatus={site.subscription_status}
            subscriptionEndDate={site.subscription_end_dttm}
          />
        </div>
        { site.company_state ? (
            <Typography><b>State:</b> {site.company_state}</Typography>
          ) : undefined
        } 
        { site.created_dttm ? (
            <Typography><b>Created:</b> {new Date(convertSYSDATEToUTC(site.created_dttm)).toLocaleDateString()}</Typography>
          ) : undefined
        } 
        { props.externalSoftwareName ? (
            <Typography><b>External Software:</b> {props.externalSoftwareName}</Typography>
          ) : undefined
        } 
        { props.affiliateGroupName ? (
            <Typography><b>Affiliate Group:</b> {props.affiliateGroupName}</Typography>
          ) : undefined
        } 
        <Button variant="contained" href={`/siteinfo/${subdomain}`} target="_blank" style={{margin: "25px 20px 0px auto"}}>
          Manage Site
        </Button>
        { ec2Data ? renderButton(ec2Data) : undefined}
      </div>
      <div style={{display: "inline-block", verticalAlign: "top", width: "25%", minHeight: "125px", position: "relative"}}>
        { !props.isEc2Loaded ? (
            <div style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", textAlign: "center", width: "80%"}}>
              <Autorenew fontSize="large" className="rotate"></Autorenew>
              <Typography><b>Loading EC2 Data</b></Typography>
            </div>
          ) : undefined
        }
        <div style={{margin: "auto auto 10px"}}>
          { ec2Data?.type ? (
              <Typography style={{display: "inline-block", verticalAlign: "middle", marginRight: "20px"}}>
                <b>Type:</b> {ec2Data?.type}
              </Typography>
            ) : undefined 
          }
          { ec2Data?.databaseInstance ? (
              <Typography style={{display: "inline-block", verticalAlign: "middle"}}>
                <b>Database:</b> {ec2Data?.databaseInstance.split('.')[0]}
              </Typography>
            ) : undefined 
          }
        </div>
        <div style={{margin: "auto auto 10px"}}>
          {siteStatus}
        </div>
        <Tooltip
          title={
            <div>
              <div>
                <Typography variant="body1">
                  RESPONSE TIME: {ec2Data?.apiStatus.responseTime ?? ''} ms
                </Typography>
              </div>
              <br></br>
              <div>
                <Typography variant="body1">
                  START TIME: {new Date(ec2Data?.startTime).toLocaleString()}
                </Typography>
              </div>
            </div>
          }>
          {!ec2Data ? (
            <div></div>
          ) : ec2Data.apiStatus.version != "unknown" ? (
            <Alert severity="success">
              {`App ${ec2Data?.apiStatus.applicationStatus ?? ''} (${appVersion})`}
            </Alert>
          ) : (
            <Alert severity="error">
              {`App ${ec2Data?.apiStatus.applicationStatus}`}
            </Alert>
          )
          }
        </Tooltip>
        
      </div>
    </div>
  );
}