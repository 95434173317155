export const getRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const JsonToCsvConverter = (data) => {
  const fields = Object.keys(data[0]);
  const replacer = function (key, value) {
    return value === null ? "" : value;
  };
  var csv = data.map(function (row) {
    return fields
      .map(function (fieldName) {
        return JSON.stringify(row[fieldName], replacer);
      })
      .join(",");
  });
  csv.unshift(fields.join(","));
  csv = csv.join("\r\n");
  return csv;
};

export const download = (data) => {
  const blob = new Blob([data], { type: `text/csv` });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  const fileName = new Date().getTime();
  a.setAttribute("hidden", "");
  a.setAttribute("href", url);
  a.setAttribute("download", `${fileName}.csv`);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const convertSYSDATEToUTC = (sysdate) => {
  if (!sysdate) return;
  
  return sysdate.replace(" ",  "T") + "Z";
}
