import React, { useContext, useState } from "react";

// MUI
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import Tooltip from "@material-ui/core/Tooltip";
// import Alert from "@material-ui/lab/Alert";

// actions
import { createUser, registerLocalUser } from "../../../Actions";

// Icon
import AddIcon from "@material-ui/icons/Add";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";

// styles
import "../../styles/Admin.css";

// dependents
import { AdminContext } from "../../../Context/AdminContextProvider";
import { AlertContext } from "../../../Context/AlertContextProvider";

// component
const CreateUser = (props) => {
  const [open, setOpen] = useState(props.open);

  const [name, setName] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [access, setAccess] = React.useState("");
  const [accessCode, setAccessCode] = React.useState("");
  const [selectedValue, setSelectedValue] = React.useState("local");

  const { setUpdate, accesstypes } = useContext(AdminContext);
  const { handleAlertOpen, setMessageType, setMessage } = useContext(
    AlertContext
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const resetvars = () => {
    setName("");
    setPassword("");
    setUsername("");
    setAccessCode("");
    setAccess("");
  };

  const handleClose = () => {
    setOpen(false);
    resetvars();
  };

  const handleName = (name) => {
    name.trim();
    setName(name);
  }

  const handleUserName = (username) => {
    username.trim();
    setUsername(username);
  };

  const handlePasswordChange = (password) => {
    password.trim();
    setPassword(password);
  };

  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value);
    resetvars();
  };

  const handleSubmit = (type) => {
    if (type === "google") {
      createUser(username, accessCode)
        .then((res) => {
          const message = res.data.message;
          setMessageType("success");
          setMessage(message);
          setUpdate(true);
          handleAlertOpen();
          setOpen(false);
        })
        .catch((err) => {
          const message = "Failed to create user!";
          setMessageType("error");
          setMessage(message);
          setOpen(false);
        });
    }
    if (type === "local") {
      registerLocalUser(name, username, password, accessCode)
        .then((res) => {
          const message = res.data.message;
          setMessageType("success");
          setMessage(message);
          setUpdate(true);
          handleAlertOpen();
          setOpen(false);
        })
        .catch((err) => {
          const message = "Failed to create user!";
          setMessageType("error");
          setMessage(message);
          setOpen(false);
        });
    }
  };

  const handleAccessChange = (e) => {
    const accessType = e.target.value;
    setAccess(accessType);
    const tempAccess = accesstypes.filter(
      (access) => access.access_type === accessType
    )[0];
    setAccessCode(tempAccess.access_type_cd);
  };

  const canCreateUser = () => {
    if (username.length) {
      return false;
    }
    return true;
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const generatePassword = (e) => {
    const randomPassword = Math.random().toString(36).slice(2);
    setPassword(randomPassword);
  };

  const renderAccessNotes = () => {
    return accesstypes.map((access) => {
      return (
        <small key={access.access_type}>
          ** <strong>{access.access_type}:</strong> {access.access_description}{" "}
          <br />
        </small>
      );
    });
  };

  const renderAccessOptions = () => {
    return (
      <Select
        className="admin__set-user-access"
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        value={access}
        onChange={handleAccessChange}
        displayEmpty
      >
        {accesstypes.map((access) => {
          return (
            <MenuItem key={access.access_type} value={access.access_type}>
              {access.access_type}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const renderForm = (type) => {
    return type === "google" ? (
      <form>
        <table>
          <tbody>
            <tr>
              <td>User Name:</td>
              <td>
                <TextField
                  label="User Name"
                  required
                  type="text"
                  name="username"
                  value={username}
                  onChange={(e) => handleUserName(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Access Level:</td>
              <td>{renderAccessOptions()}</td>
            </tr>
          </tbody>
        </table>
      </form>
    ) : (
      <form>
        <table>
          <tbody>
          <tr>
              <td>Name:</td>
              <td>
                <TextField
                  label="Name"
                  required
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => handleName(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>User Name:</td>
              <td>
                <TextField
                  label="User Name"
                  required
                  type="text"
                  name="username"
                  value={username}
                  onChange={(e) => handleUserName(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>Password:</td>
              <td>
                <FormControl>
                  <InputLabel htmlFor="standard-adornment-password">
                    Password
                  </InputLabel>
                  <Input
                    id="standard-adornment-password"
                    type="text"
                    value={password}
                    onChange={(e) => handlePasswordChange(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={generatePassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          <Tooltip title="generate password">
                            <CachedOutlinedIcon />
                          </Tooltip>
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </td>
            </tr>
            <tr>
              <td>Access Level:</td>
              <td>{renderAccessOptions()}</td>
            </tr>
          </tbody>
        </table>
      </form>
    );
  };

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <AddIcon style={{ fill: "rgba(9, 150, 42, 0.7)" }} />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          className="Admin__create-user-header"
          id="alert-dialog-title"
        >
          {"Create User"}
        </DialogTitle>
        <DialogContent dividers>
          {/* <Paper className="Admin_create-user-form"> */}

          <FormControl component="fieldset">
            <Typography className="create-user__subtitle" variant="subtitle2">
              Select user creation method
            </Typography>
            <RadioGroup
              className="create-user__type"
              aria-label="gender"
              name="gender1"
              value={selectedValue}
              onChange={handleRadioChange}
            >
              {/* <FormControlLabel
                value="google"
                disabled
                control={<Radio className="create-user__radio" />}
                label="Google"
              /> */}
              <FormControlLabel
                value="local"
                control={<Radio className="create-user__radio" />}
                label="Local"
              />
            </RadioGroup>
          </FormControl>
          {renderForm(selectedValue)}
          {/* </Paper> */}
          <p>{renderAccessNotes()}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
          <Button
            onClick={(e) => handleSubmit(selectedValue)}
            className="Admin__create-user"
            color="primary"
            autoFocus
            disabled={canCreateUser()}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateUser;
