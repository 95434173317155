import React, { useContext, useEffect } from "react";

// MUI
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

// styles
import "../styles/Home.css";

// dependends
import Announcements from "./Announcements";
import Bulletins from "./UserBulletin/Bulletins";
import SiteStatus from "../SiteStatus/SiteStatus";

const Home = () => {

  return (
    <div className="home__content">
      <Grid container spacing={3}>
        <Grid item xs={6}>
            <div className="home__bulletin">
        <Paper elevation={1}>
          {/* <Bulletins /> */}
        </Paper>
      </div>
      </Grid>
      <Grid item xs={12}>
      <div className="home__activity">
        <div className="home__announcements">
          {/* <Paper className="home__announcement-box" elevation={1}> */}
            <Announcements key={1}/>
          {/* </Paper> */}
        </div>
      </div>
      </Grid>
      <Grid item xs={12}>
      <div style ={{width:'100%'}}>
        <Paper elevation={1}>
          <SiteStatus />
        </Paper>
      </div>
      </Grid>
      </Grid>
    </div>
  );
};

export default Home;
