import React from "react";
import { useHistory, useLocation, withRouter } from "react-router-dom";

// MUI
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

// constants
import { baseUrl } from "../../Contants";

// Context
import { AppContext } from "../../Context/AppContextProvide";
import { AlertContext } from "../../Context/AlertContextProvider";

// CSS
import "../styles/Signin.css";
import { login } from "../../Actions";

const Signin = () => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { setAuthType, setAccessToken } = React.useContext(AppContext);
  const { handleAlertOpen, setMessageType, setMessage } = React.useContext(
    AlertContext
  );
  const history = useHistory();
  const location = useLocation();

  const handleUsernameChange = (e) => setUsername(e.target.value);

  const handlePasswordChange = (e) => setPassword(e.target.value);

  const onSubmit = () => {
    login(username, password)
      .then((result) => {
        setAuthType("local");
        setAccessToken(result.data.token);
        localStorage.setItem("accessToken", result.data.token);
        localStorage.setItem("authType", "local");
        setTimeout(() => {
          location.state && location.state.from
            ? history.push(location.state.from.pathname)
            : history.push("/home");
        }, 500);
      })
      .catch((err) => {
        setMessageType("error");
        setMessage("Invalid username/password");
        handleAlertOpen(true);
      });
  };

  const isDisabled = () => {
    return !(username.length && password.length);
  };

  return (
    <div className="signin__container">
      <div className="signin__google">
        <div className="signin__google-btn">
          {/* <button className="loginBtn loginBtn--google">
            <a className="login__google" href={`${baseUrl}/api/auth/google`}>
              Login with Google
            </a>
          </button> */}
        </div>
        <Divider variant="middle" />
      </div>

      <div className="signin__local">
        <Typography variant="h6" align="center">
          Login
        </Typography>
        <TextField
          value={username}
          onChange={handleUsernameChange}
          className="signin__username"
          label="username"
          type="text"
        />
        <TextField
          value={password}
          onChange={handlePasswordChange}
          className="signin__password"
          label="password"
          type="password"
        />
        <div className="signin_local-btn-container">
          <Button
            disabled={isDisabled()}
            onClick={onSubmit}
            className="sigin__local-btn"
          >
            Login
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Signin);
