import { Button, Chip, Dialog, Grid, MenuItem, Paper, Select, TextField, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { addCompany, addContact, addEventToLead, addLead, addMarket, addMarketsToCompany } from "../../Actions";
import { AlertContext } from "../../Context/AlertContextProvider";
import { AppContext } from "../../Context/AppContextProvide";

export default function CreateLead(props) {
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedMarket, setSelectedMarket] = useState("");
  const [selectedMarketOptions, setSelectedMarketOptions] = useState([]);
  const [newMarketName, setNewMarketName] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedRevenueTier, setSelectedRevenueTier] = useState("");
  const [selectedReferralType, setSelectedReferralType] = useState("");
  const [selectedAffiliateGroup, setSelectedAffiliateGroup] = useState("");
  const [selectedExternalSoftware, setSelectedExternalSoftware] = useState("");
  const [locationCount, setLocationCount] = useState("");

  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);
  const { user } = useContext(AppContext);

  useEffect(() => {
    setCompanyName("");
    setAddress("");
    setCity("");
    setState("");
    setZipCode("");
    setCountry("United States");
    setWebsiteUrl("");
    setContactName("");
    setEmail("");
    setPhone("");
    setSelectedMarket("");
    setSelectedMarketOptions([]);
    setNewMarketName("");
    setSelectedUser("");
    setSelectedRevenueTier("");
    setSelectedReferralType("");
    setSelectedAffiliateGroup("");
    setSelectedExternalSoftware("");
    setLocationCount("");
  }, [props.isOpen])

  const addMarketToList = async () => {

    if (selectedMarketOptions.includes(selectedMarket)) return;

    if (selectedMarket === "-1") {
      await addMarket(newMarketName)
        .then(result => {
          setMessage("Successfully created new market")
          setMessageType("success");
          handleAlertOpen();
          
          setSelectedMarket("");
          setNewMarketName("");
          setSelectedMarketOptions([...selectedMarketOptions, result.data.data.insertId]);

          props.refreshData();
        }).catch(err => {
          setMessage("Error creating market")
          setMessageType("error");
          handleAlertOpen();
        });

      return;
    }

    setSelectedMarket("");
    setSelectedMarketOptions([...selectedMarketOptions, selectedMarket]);
  } 

  const removeMarketFromList = (marketId) => {

    if (!selectedMarketOptions.includes(marketId)) return;

    const filteredOptions = selectedMarketOptions.filter(option => option !== marketId);
    setSelectedMarketOptions(filteredOptions);
  }

  const isCompanyValid = () => {
    return companyName && address && city && state && zipCode && country;
  }

  const submitLead = async () => {

    if (!isCompanyValid() || !selectedMarketOptions.length || selectedUser === "") {
      setMessage("Error creating lead. Please check required fields")
      setMessageType("error");
      handleAlertOpen();

      return;
    }

    let companyId;

    await addCompany(companyName, websiteUrl, address, city, state, zipCode, country)
      .then(result => {
        companyId = result.data.data.insertId;
      }).catch(err => {
        setMessage("Error creating company for lead");
        setMessageType("error");
        handleAlertOpen();

        return;
      });

    if (!companyId) {
      setMessage("Error creating lead [no company id]");
      setMessageType("error");
      handleAlertOpen();

      return;
    }

    await addMarketsToCompany(companyId, selectedMarketOptions)
      .catch(err => {
        setMessage("Error adding market(s) to the company");
        setMessageType("error");
        handleAlertOpen();

        return;
      });

    let leadId;

    await addLead(
      1, 
      companyId, 
      selectedExternalSoftware ? selectedExternalSoftware : null, 
      selectedAffiliateGroup ? selectedAffiliateGroup : null, 
      selectedReferralType ? selectedReferralType : null, 
      selectedRevenueTier ? selectedRevenueTier : null, 
      locationCount ? parseInt(locationCount) : null, 
      selectedUser !== "-1" ? selectedUser : null
    ).then(result => {
        leadId = result.data.data.insertId;

        const marketNameList = selectedMarketOptions?.map(market => {
          const marketName = props.markets.find(m => m.market_id === market)?.name;

          return marketName ?? ''
        }).filter(m => !!m);
        
        if (marketNameList) addEventToLead(leadId, `added market(s) [${marketNameList.join(', ')}] to this lead`);
        
        if (selectedUser === "-1") {
          addEventToLead(leadId, `updated this lead to [unassigned]`);
        } else {
          const userName = props.users?.find(user => user.user_id === selectedUser)?.name;

          if (userName) addEventToLead(leadId, `assigned [${user.user_id === selectedUser ? 'themself' : userName}] to this lead`);
        }
      }).catch(err => {
        setMessage("Error creating lead");
        setMessageType("error");
        handleAlertOpen();

        return;
      });

    if (!contactName && !email && !phone) {
      setMessage("Successfully created lead!");
      setMessageType("success");
      handleAlertOpen();
      
      props.refreshData();
      props.onClose();

      return;
    }

    if (!leadId) {
      setMessage("Error creating contact for lead [no lead id]");
      setMessageType("error");
      handleAlertOpen();

      return;
    }

    await addContact(contactName, email, phone, leadId)
      .then(result => {
        setMessage("Successfully created lead!");
        setMessageType("success");
        handleAlertOpen();

        addEventToLead(leadId, `added contact for [${contactName}] to this lead`);
    
        props.refreshData();
        props.onClose();
      }).catch(err => {
        setMessage("Error creating contact lead");
        setMessageType("error");
        handleAlertOpen();

        return;
      });
  }

  return (
    <Dialog 
      open={props.isOpen} 
      onClose={() => props.onClose()}
      maxWidth={false}
    >
      <Paper style={{overflowY: "hidden", width: "45vw", minWidth: "300px"}}>
        <Typography 
          className="siteinfo__header"
          variant="h4" 
        >
          <b>Create Lead</b>
        </Typography>
        <div style={{padding: "20px 20px 50px", maxHeight: "70vh", overflowY: "scroll"}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6"><b>Company</b></Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Company Name *</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Website URL</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={websiteUrl}
                onChange={(e) => setWebsiteUrl(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>Street Address *</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography>City *</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography>State *</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={state}
                onChange={(e) => setState(e.target.value)}
                placeholder="MO"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography>Zip Code *</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography>Country *</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                placeholder="United States"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" style={{marginTop: "10px"}}><b>Contact</b></Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Name</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
                placeholder="First Last"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Email</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="example@test.com"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Phone</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="012-345-6789"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" style={{marginTop: "10px"}}><b>Additional Info</b></Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Market *</Typography>
              <Select
                value={selectedMarket}
                onChange={(e) => {
                  setSelectedMarket(e.target.value);
                  setNewMarketName("");
                }}
                variant="filled"
                displayEmpty
                fullWidth
              >
                <MenuItem value="" disabled>Please Select a Market</MenuItem>
                <MenuItem value="-1">New Option</MenuItem>
                { 
                  props.markets?.map(market => {
                    return (
                      <MenuItem key={market.market_id} value={market.market_id}>
                        {market.name}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              { selectedMarket === "-1" ? (
                  <div>
                    <Typography>New Market</Typography>
                    <TextField
                      value={newMarketName}
                      onChange={(e) => setNewMarketName(e.target.value)}
                      variant="filled"
                      fullWidth
                    />
                  </div>
                ) : undefined
              }
            </Grid>
            <Grid item xs={12}>
              <Button 
                variant="contained" 
                style={{backgroundColor: "#142e3e", color: "#28c4fc"}}
                onClick={() => addMarketToList()}
              >
                Add {selectedMarket === "-1" ? " New " : undefined} Market
              </Button>
            </Grid>
            <Grid item xs={12}>
              { 
                selectedMarketOptions.map(option => {
                  const marketObj = props.markets.find(m => m.market_id === option);

                  if (!marketObj) return undefined;

                  return (
                    <Chip 
                      key={marketObj.market_id}
                      label={marketObj.name}
                      style={{margin: "5px 10px 5px 0px"}}
                      variant="outlined"
                      onDelete={() => removeMarketFromList(marketObj.market_id)}
                    />
                  )
                })
              }
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Assigned To *</Typography>
              <Select
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
                variant="filled"
                displayEmpty
                fullWidth
              >
                <MenuItem value="">Please Select a User</MenuItem>
                <MenuItem value="-1">Unassigned</MenuItem>
                { 
                  props.users?.map(user => {
                    return (
                      <MenuItem key={user.user_id} value={user.user_id}>
                        {user.name}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Revenue Tier</Typography>
              <Select
                value={selectedRevenueTier}
                onChange={(e) => setSelectedRevenueTier(e.target.value)}
                variant="filled"
                displayEmpty
                fullWidth
              >
                <MenuItem value="">Please Select a Revenue Tier</MenuItem>
                { 
                  props.revenueTiers?.map(tier => {
                    return (
                      <MenuItem key={tier.revenue_tier_id} value={tier.revenue_tier_id}>
                        {tier.description}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Referral Type</Typography>
              <Select
                value={selectedReferralType}
                onChange={(e) => setSelectedReferralType(e.target.value)}
                variant="filled"
                displayEmpty
                fullWidth
              >
                <MenuItem value="">Please Select a Referral Type</MenuItem>
                { 
                  props.referralTypes?.map(type => {
                    return (
                      <MenuItem key={type.referral_type_id} value={type.referral_type_id}>
                        {type.name}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>External Software</Typography>
              <Select
                value={selectedExternalSoftware}
                onChange={(e) => setSelectedExternalSoftware(e.target.value)}
                variant="filled"
                displayEmpty
                fullWidth
              >
                <MenuItem value="">Please Select a Software</MenuItem>
                { 
                  props.externalSoftwares?.map(software => {
                    return (
                      <MenuItem key={software.external_software_id} value={software.external_software_id}>
                        {software.name}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>Affiliate Group</Typography>
              <Select
                value={selectedAffiliateGroup}
                onChange={(e) => setSelectedAffiliateGroup(e.target.value)}
                variant="filled"
                displayEmpty
                fullWidth
              >
                <MenuItem value="">Please Select an Affiliate Group</MenuItem>
                { 
                  props.affiliateGroups?.map(group => {
                    return (
                      <MenuItem key={group.affiliate_group_id} value={group.affiliate_group_id}>
                        {group.name}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography># of Locations</Typography>
              <TextField
                type="number"
                fullWidth
                variant="filled"
                InputProps={{
                  min: 0
                }}
                value={locationCount}
                onChange={(e) => setLocationCount(e.target.value)}
              />
            </Grid>
          </Grid>
        </div>
        <div style={{padding: "10px", textAlign: "right", borderTop: "1px solid #838383"}}>
          <Button 
            variant="contained" 
            style={{margin: "10px"}} 
            onClick={() => props.onClose()}
          >
            Cancel
          </Button>
          <Button 
            variant="contained" 
            style={{margin: "10px", backgroundColor: "#4caf50", color: "#FFFFFF"}}
            onClick={() => submitLead()}
          >
            Submit Lead
          </Button>
        </div>
      </Paper>
    </Dialog>
  )
}