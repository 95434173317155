import { Alert } from "@material-ui/lab";
import React from "react";

export default function SubscriptionStatus(props) {
  let severity;
  let verbiage;

  switch (props.subscriptionStatus) {
    case 0:
      severity = "success";
      verbiage = "Subscription active";
      break;
    case 1:
      const endDate = new Date(props.subscriptionEndDate?.split(" ")[0]);
      const currentDate = new Date();
      const retentionDate = new Date(endDate);
      retentionDate.setMonth(endDate.getMonth() + 2);
      severity = endDate.getTime() > currentDate.getTime() ? "warning" : "error";

      if (endDate.getTime() > currentDate.getTime()) {
        verbiage = "Subscription ending on " + endDate.toLocaleDateString();
      } else if (endDate.getTime() <= currentDate.getTime() && currentDate.getTime() <= retentionDate.getTime()) {
        verbiage = "Subscription in grace period until " + retentionDate.toLocaleDateString();
      } else {
        verbiage = "Subscription deactivated";
      }
      
      break;
    case 2:
      severity = "warning";
      verbiage = "Subscription paused";
      break;
    default:
      severity = "info";
      verbiage = "No status";
  }

  return (
    <Alert severity={severity} style={{width: "50%", maxWidth: "300px", margin: "20px 0px"}}>
      {verbiage}
    </Alert>
  )
}