import React, { useContext, useEffect, useState } from "react";
import { Button, Chip, Dialog, Grid, MenuItem, Paper, Select, TextField, Typography } from "@material-ui/core";
import { addContact, addEventToLead, addMarket, updateCompany, updateContact, updateLeadAssignment, updateLeadAttributes, updateLeadMarkets, updateLeadStatus } from "../../Actions";
import { AlertContext } from "../../Context/AlertContextProvider";
import { AppContext } from "../../Context/AppContextProvide";

export function AssignDialog(props) {
  const [selectedUser, setSelectedUser] = useState("");

  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);
  const { user } = useContext(AppContext);

  useEffect(() => {
    if (props.lead) {
      setSelectedUser(props.lead.assigned_to ?? "-1");
    }
  }, [props.lead])

  const updateAssignment = async (leadId, assignedTo) => {
    await updateLeadAssignment(leadId, assignedTo ? assignedTo : null)
      .then(result => {
        setMessage("Successfully updated lead assignment!");
        setMessageType("success");
        handleAlertOpen();

        if (assignedTo === null) {
          addEventToLead(leadId, `updated this lead to [unassigned]`);
        } else {
          const userName = props.users?.find(user => user.user_id === selectedUser)?.name;

          if (userName) addEventToLead(leadId, `assigned [${user.user_id === selectedUser ? 'themself' : userName}] to this lead`);
        }

        props.refreshData();
        props.onClose();
      }).catch(err => {
        setMessage("Error updating lead assignment");
        setMessageType("error");
        handleAlertOpen();
      });
  }

  return (
    <Dialog
      open={props.isOpen} 
      onClose={() => props.onClose()}
      maxWidth={false}
    >
      <Paper style={{width: "45vw", minWidth: "300px"}}>
        <Typography 
          className="siteinfo__header"
          variant="h4" 
        >
          <b>Edit Lead Assignment</b>
        </Typography>
        <div style={{padding: "20px"}}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography>Assigned To</Typography>
              <Select
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
                variant="filled"
                displayEmpty
                fullWidth
              >
                <MenuItem value="">Please Select a User</MenuItem>
                <MenuItem value="-1">Unassign</MenuItem>
                { 
                  props.users?.map(user => {
                    return (
                      <MenuItem key={user.user_id} value={user.user_id}>
                        {user.name}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item xs={12} style={{marginTop: "20px", textAlign: "right"}}> 
              <Button 
                variant="contained" 
                style={{marginRight: "20px"}}
                onClick={() => props.onClose()}
              >
                Cancel
              </Button>
              <Button 
                variant="contained" 
                onClick={() => updateAssignment(props.lead.lead_id, selectedUser !== "-1" ? selectedUser : null)}
                style={{backgroundColor: "#4caf50", color: "#FFFFFF"}}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Dialog>
  )
}

export function LeadStatusDialog(props) {
  const [selectedLeadStatus, setSelectedLeadStatus] = useState("");

  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);
  
  useEffect(() => {
    if (props.lead) {
      setSelectedLeadStatus(props.lead.lead_status_id);
    }
  }, [props.lead])

  const updateStatus = async (leadId, leadStatusId) => {
    if (!leadStatusId) {
      setMessage("Error updating lead assignment. Status required.");
      setMessageType("error");
      handleAlertOpen();
      
      return;
    }

    await updateLeadStatus(leadId, leadStatusId)
      .then(result => {
        setMessage("Successfully updated lead assignment!");
        setMessageType("success");
        handleAlertOpen();

        const statusName = props.statuses?.find(status => status.lead_status_id === leadStatusId)?.name;

        if (statusName) addEventToLead(leadId, `updated lead status to [${statusName}]`);

        props.refreshData();
        props.onClose();
      }).catch(err => {
        setMessage("Error updating lead assignment");
        setMessageType("error");
        handleAlertOpen();
      });
  }

  return (
    <Dialog
      open={props.isOpen} 
      onClose={() => props.onClose()}
      maxWidth={false}
    >
      <Paper style={{width: "45vw", minWidth: "300px"}}>
        <Typography 
          className="siteinfo__header"
          variant="h4" 
        >
          <b>Edit Lead Status</b>
        </Typography>
        <div style={{padding: "20px"}}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography>Lead Status</Typography>
              <Select
                value={selectedLeadStatus}
                onChange={(e) => setSelectedLeadStatus(e.target.value)}
                variant="filled"
                fullWidth
                displayEmpty
              >
                <MenuItem value="" disabled>Please Select a Status</MenuItem>
                { 
                  props.statuses?.map(status => {
                    return (
                      <MenuItem key={status.lead_status_id} value={status.lead_status_id}>
                        {status.name}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item xs={12} style={{marginTop: "20px", textAlign: "right"}}>
              <Button 
                variant="contained" 
                style={{marginRight: "20px"}}
                onClick={() => props.onClose()}
              >
                Cancel
              </Button>
              <Button 
                variant="contained" 
                onClick={() => updateStatus(props.lead.lead_id, selectedLeadStatus)}
                style={{backgroundColor: "#4caf50", color: "#FFFFFF"}}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Dialog>
  )
}

export function MarketDialog(props) {
  const [selectedMarket, setSelectedMarket] = useState("");
  const [newMarketName, setNewMarketName] = useState("");
  const [selectedMarketOptions, setSelectedMarketOptions] = useState([]);

  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);

  useEffect(() => {
    if (props.lead) {
      const marketIds = props.lead.markets?.map(market => market.market_id);
      setSelectedMarketOptions(marketIds ?? []);
    }
  }, [props.lead])

  const updateMarkets = async (leadId, companyId, selectedMarketOptions) => {
    if (!selectedMarketOptions.length) {
      setMessage("Error updating market(s). No market(s) selected.");
      setMessageType("error");
      handleAlertOpen();

      return;
    }

    await updateLeadMarkets(leadId, companyId, selectedMarketOptions)
      .then(result => {
        setMessage("Successfully updated market(s)")
        setMessageType("success");
        handleAlertOpen();

        const marketNameList = selectedMarketOptions?.map(market => {
          const marketName = props.markets.find(m => m.market_id === market)?.name;

          return marketName ?? ''
        }).filter(m => !!m);
        
        if (marketNameList) addEventToLead(leadId, `updated market(s) serviced to [${marketNameList.join(', ')}]`);

        props.refreshData();
        props.onClose();
      }).catch(err => {
        setMessage("Error updating market(s)");
        setMessageType("error");
        handleAlertOpen();

        return;
      });
  }

  const addMarketToList = async () => {

    if (selectedMarketOptions.includes(selectedMarket)) return;

    if (selectedMarket === "-1") {
      await addMarket(newMarketName)
        .then(result => {
          setMessage("Successfully created new market")
          setMessageType("success");
          handleAlertOpen();
          
          setSelectedMarket("");
          setNewMarketName("");
          setSelectedMarketOptions([...selectedMarketOptions, result.data.data.insertId]);

          props.refreshData();
        }).catch(err => {
          setMessage("Error creating market")
          setMessageType("error");
          handleAlertOpen();
        });

      return;
    }

    setSelectedMarket("");
    setSelectedMarketOptions([...selectedMarketOptions, selectedMarket]);
  } 

  const removeMarketFromList = (marketId) => {

    if (!selectedMarketOptions.includes(marketId)) return;

    const filteredOptions = selectedMarketOptions.filter(option => option !== marketId);
    setSelectedMarketOptions(filteredOptions);
  }
  

  return (
    <Dialog
      open={props.isOpen} 
      onClose={() => props.onClose()}
    >
      <Paper>
        <Typography 
          className="siteinfo__header"
          variant="h4" 
        >
          <b>Edit Markets</b>
        </Typography>
        <div style={{padding: "20px"}}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography>Market</Typography>
              <Select
                value={selectedMarket}
                onChange={(e) => {
                  setSelectedMarket(e.target.value);
                  setNewMarketName("");
                }}
                variant="filled"
                displayEmpty
                fullWidth
              >
                <MenuItem value="" disabled>Please Select a Market</MenuItem>
                <MenuItem value="-1">New Option</MenuItem>
                { 
                  props.markets?.map(market => {
                    return (
                      <MenuItem key={market.market_id} value={market.market_id}>
                        {market.name}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              { selectedMarket === "-1" ? (
                  <div>
                    <Typography>New Market</Typography>
                    <TextField
                      value={newMarketName}
                      onChange={(e) => setNewMarketName(e.target.value)}
                      variant="filled"
                      fullWidth
                    />
                  </div>
                ) : undefined
              }
            </Grid>
            <Grid item xs={12}>
              <Button 
                variant="contained" 
                style={{backgroundColor: "#142e3e", color: "#28c4fc"}}
                onClick={() => addMarketToList()}
              >
                Add {selectedMarket === "-1" ? " New " : undefined} Market
              </Button>
            </Grid>
            <Grid item xs={12}>
              { 
                selectedMarketOptions.map(option => {
                  const marketObj = props.markets?.find(m => m.market_id === option);

                  if (!marketObj) return undefined;

                  return (
                    <Chip 
                      key={marketObj.market_id}
                      label={marketObj.name}
                      style={{margin: "5px 10px 5px 0px"}}
                      variant="outlined"
                      onDelete={() => removeMarketFromList(marketObj.market_id)}
                    />
                  )
                })
              }
            </Grid>
            <Grid item xs={12} style={{marginTop: "20px", textAlign: "right"}}>
              <Button 
                variant="contained" 
                style={{marginRight: "20px"}}
                onClick={() => props.onClose()}
              >
                Cancel
              </Button>
              <Button 
                variant="contained" 
                onClick={() => updateMarkets(props.lead.lead_id, props.lead.company_id, selectedMarketOptions) }
                style={{backgroundColor: "#4caf50", color: "#FFFFFF"}}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Dialog>
  )
}

export function AttributeDialog(props) {
  const [selectedRevenueTier, setSelectedRevenueTier] = useState("");
  const [selectedReferralType, setSelectedReferralType] = useState("");
  const [selectedAffiliateGroup, setSelectedAffiliateGroup] = useState("");
  const [selectedExternalSoftware, setSelectedExternalSoftware] = useState("");
  const [locationCount, setLocationCount] = useState("");

  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);

  useEffect(() => {
    if (props.lead) {
      setSelectedRevenueTier(props.lead.revenue_tier_id ?? "");
      setSelectedReferralType(props.lead.referral_type_id ?? "");
      setSelectedAffiliateGroup(props.lead.affiliate_group_id ?? "");
      setSelectedExternalSoftware(props.lead.external_software_id ?? "");
      setLocationCount(props.lead.location_count ?? "");
    }
  }, [props.lead, props.isOpen])

  const updateAttributes = async (leadId, revenueTierId, referralTypeId, affiliateGroupId, externalSoftwareId, locationCount) => {

    await updateLeadAttributes(leadId, revenueTierId, referralTypeId, affiliateGroupId, externalSoftwareId, locationCount ? parseInt(locationCount) : null)
      .then(result => {
        setMessage("Successfully updated lead attributes!");
        setMessageType("success");
        handleAlertOpen();

        if (props.lead.revenue_tier_id !== revenueTierId) {
          const tierName = props.revenueTiers?.find(tier => tier.revenue_tier_id === revenueTierId)?.description;
          const eventStr = revenueTierId ? `updated the revenue tier to [${tierName}]` : 'unset the revenue tier';

          if (!revenueTierId || tierName) addEventToLead(leadId, eventStr);
        }

        if (props.lead.referral_type_id !== referralTypeId) {
          const typeName = props.referralTypes?.find(type => type.referral_type_id === referralTypeId)?.name;
          const eventStr = referralTypeId ? `updated the referral type to [${typeName}]` : 'unset the referral type';

          if (!referralTypeId || typeName) addEventToLead(leadId, eventStr);
        }

        if (props.lead.affiliate_group_id !== affiliateGroupId) {
          const groupName = props.affiliateGroups?.find(group => group.affiliate_group_id === affiliateGroupId)?.name;
          const eventStr = affiliateGroupId ? `updated the affiliate group to [${groupName}]` : 'unset the affiliate group';

          if (!affiliateGroupId || groupName) addEventToLead(leadId, eventStr);
        }

        if (props.lead.external_software_id !== externalSoftwareId) {
          const softwareName = props.externalSoftwares?.find(software => software.external_software_id === externalSoftwareId)?.name;
          const eventStr = externalSoftwareId ? `updated the external software to [${softwareName}]` : 'unset the external software';

          if (!externalSoftwareId || softwareName) addEventToLead(leadId, eventStr);
        }
        if (props.lead.location_count !== locationCount) {
          const eventStr = locationCount ? `updated the location count to [${locationCount}]` : 'unset the location count';
          addEventToLead(leadId, eventStr);
        }

        props.refreshData();
        props.onClose();
      }).catch(err => {
        setMessage("Error updating lead attributes");
        setMessageType("error");
        handleAlertOpen();
      });
  }

  return (
    <Dialog
      open={props.isOpen} 
      onClose={() => props.onClose()}
      maxWidth={false}
    >
      <Paper style={{width: "45vw", minWidth: "300px"}}>
        <Typography 
          className="siteinfo__header"
          variant="h4" 
        >
          <b>Edit Attributes</b>
        </Typography>
        <div style={{padding: "20px"}}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography>Revenue Tier</Typography>
              <Select
                value={selectedRevenueTier}
                onChange={(e) => setSelectedRevenueTier(e.target.value)}
                variant="filled"
                displayEmpty
                fullWidth
              >
                <MenuItem value="">Please Select a Revenue Tier</MenuItem>
                { 
                  props.revenueTiers?.map(tier => {
                    return (
                      <MenuItem key={tier.revenue_tier_id} value={tier.revenue_tier_id}>
                        {tier.description}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Referral Type</Typography>
              <Select
                value={selectedReferralType}
                onChange={(e) => setSelectedReferralType(e.target.value)}
                variant="filled"
                displayEmpty
                fullWidth
              >
                <MenuItem value="">Please Select a Referral Type</MenuItem>
                { 
                  props.referralTypes?.map(type => {
                    return (
                      <MenuItem key={type.referral_type_id} value={type.referral_type_id}>
                        {type.name}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>External Software</Typography>
              <Select
                value={selectedExternalSoftware}
                onChange={(e) => setSelectedExternalSoftware(e.target.value)}
                variant="filled"
                displayEmpty
                fullWidth
              >
                <MenuItem value="">Please Select a Software</MenuItem>
                { 
                  props.externalSoftwares?.map(software => {
                    return (
                      <MenuItem key={software.external_software_id} value={software.external_software_id}>
                        {software.name}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Affiliate Group</Typography>
              <Select
                value={selectedAffiliateGroup}
                onChange={(e) => setSelectedAffiliateGroup(e.target.value)}
                variant="filled"
                displayEmpty
                fullWidth
              >
                <MenuItem value="">Please Select an Affiliate Group</MenuItem>
                { 
                  props.affiliateGroups?.map(group => {
                    return (
                      <MenuItem key={group.affiliate_group_id} value={group.affiliate_group_id}>
                        {group.name}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography># of Locations</Typography>
              <TextField
                type="number"
                fullWidth
                variant="filled"
                InputProps={{
                  min: 0
                }}
                value={locationCount}
                onChange={(e) => setLocationCount(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} style={{marginTop: "20px", textAlign: "right"}}>
              <Button 
                variant="contained" 
                style={{marginRight: "20px"}}
                onClick={() => props.onClose()}
              >
                Cancel
              </Button>
              <Button 
                variant="contained"
                onClick={() => updateAttributes(
                  props.lead.lead_id, 
                  selectedRevenueTier ? selectedRevenueTier : null, 
                  selectedReferralType ? selectedReferralType : null, 
                  selectedAffiliateGroup ? selectedAffiliateGroup : null, 
                  selectedExternalSoftware ? selectedExternalSoftware : null, 
                  locationCount ? locationCount : null
                )} 
                style={{backgroundColor: "#4caf50", color: "#FFFFFF"}}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Dialog>
  )
}

export function AddContactDialog(props) {
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);

  useEffect(() => {
    setContactName("");
    setEmail("");
    setPhone("");
  }, [props.isOpen])
  

  const submitContact = async (leadId, contactName, email, phone) => {
    if (!contactName) {
      setMessage("Error creating lead contact. Contact name missing");
      setMessageType("error");
      handleAlertOpen();

      return;
    }

    if (!email && !phone) {
      setMessage("Error creating lead contact. Either an email or a phone number is required");
      setMessageType("error");
      handleAlertOpen();

      return;
    }

    await addContact(contactName, email, phone, leadId)
      .then(result => {
        setMessage("Successfully created lead contact!");
        setMessageType("success");
        handleAlertOpen();

        addEventToLead(leadId, `added contact for [${contactName}] to this lead`);
    
        props.refreshData();
        props.onClose();
      }).catch(err => {
        setMessage("Error creating lead contact");
        setMessageType("error");
        handleAlertOpen();

        return;
      });
  }

  return (
    <Dialog
      open={props.isOpen} 
      onClose={() => props.onClose()}
      maxWidth={false}
    >
      <Paper style={{width: "45vw", minWidth: "300px"}}>
        <Typography 
          className="siteinfo__header"
          variant="h4" 
        >
          <b>Add Contact</b>
        </Typography>
        <div style={{padding: "20px"}}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography>Name *</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
                placeholder="First Last"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Email</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="example@test.com"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Phone</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="012-345-6789"
              />
            </Grid>
            <Grid item xs={12} style={{marginTop: "20px", textAlign: "right"}}>
              <Button 
                variant="contained" 
                style={{marginRight: "20px"}}
                onClick={() => props.onClose()}
              >
                Cancel
              </Button>
              <Button 
                variant="contained"
                onClick={() => submitContact(props.lead.lead_id, contactName, email ? email : null, phone ? phone : null)} 
                style={{backgroundColor: "#4caf50", color: "#FFFFFF"}}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Dialog>
  )
}

export function UpdateContactDialog(props) {
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);

  useEffect(() => {
    const contact = props.lead.contacts?.find(contact => contact.contact_id === props.contactId);

    setContactName(contact?.name ?? "");
    setEmail(contact?.email ?? "");
    setPhone(contact?.phone ?? "");
  }, [props.isOpen])
  

  const submitContact = async (leadId, contactId, contactName, email, phone) => {

    if (!contactName) {
      setMessage("Error updating lead contact. Contact name missing");
      setMessageType("error");
      handleAlertOpen();

      return;
    }

    if (!email && !phone) {
      setMessage("Error updating lead contact. Either an email or a phone number is required");
      setMessageType("error");
      handleAlertOpen();

      return;
    }

    await updateContact(leadId, contactId, contactName, email, phone)
      .then(result => {
        setMessage("Successfully updated lead contact!");
        setMessageType("success");
        handleAlertOpen();

        addEventToLead(leadId, `updated contact for [${contactName}]`);
    
        props.refreshData();
        props.onClose();
      }).catch(err => {
        setMessage("Error updating lead contact");
        setMessageType("error");
        handleAlertOpen();

        return;
      });
  }

  return (
    <Dialog
      open={props.isOpen} 
      onClose={() => props.onClose()}
      maxWidth={false}
    >
      <Paper style={{width: "45vw", minWidth: "300px"}}>
        <Typography 
          className="siteinfo__header"
          variant="h4" 
        >
          <b>Edit Contact</b>
        </Typography>
        <div style={{padding: "20px"}}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography>Name *</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
                placeholder="First Last"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Email</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="example@test.com"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Phone</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="012-345-6789"
              />
            </Grid>
            <Grid item xs={12} style={{marginTop: "20px", textAlign: "right"}}>
              <Button 
                variant="contained" 
                style={{marginRight: "20px"}}
                onClick={() => props.onClose()}
              >
                Cancel
              </Button>
              <Button 
                variant="contained"
                onClick={() => submitContact(props.lead.lead_id, props.contactId, contactName, email ? email : null, phone ? phone : null)} 
                style={{backgroundColor: "#4caf50", color: "#FFFFFF"}}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Dialog>
  )
}

export function UpdateCompanyDialog(props) {
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");

  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);

  useEffect(() => {
    setCompanyName(props.lead.company?.company ?? "");
    setAddress(props.lead.company?.address ?? "");
    setCity(props.lead.company?.city ?? "");
    setState(props.lead.company?.state ?? "");
    setZipCode(props.lead.company?.zip ?? "");
    setCountry(props.lead.company?.country ?? "");
    setWebsiteUrl(props.lead.company?.website_url ?? "");
  }, [props.isOpen])
  

  const submitCompany = async (leadId, companyId, name, websiteUrl, address, city, state, zipCode, country) => {

    if (!name || !address || !city || !state || !zipCode || !country) {
      setMessage("Error updating company. Please check required fields");
      setMessageType("error");
      handleAlertOpen();

      return;
    }

    await updateCompany(companyId, name, websiteUrl, address, city, state, zipCode, country)
      .then(result => {
        setMessage("Successfully updated company!");
        setMessageType("success");
        handleAlertOpen();

        addEventToLead(leadId, `updated the company information for this lead`);
    
        props.refreshData();
        props.onClose();
        return;
      }).catch(err => {
        setMessage("Error updating company");
        setMessageType("error");
        handleAlertOpen();
        
        return;
      });
  }

  return (
    <Dialog
      open={props.isOpen} 
      onClose={() => props.onClose()}
      maxWidth={false}
    >
      <Paper style={{width: "45vw", minWidth: "300px"}}>
        <Typography 
          className="siteinfo__header"
          variant="h4" 
        >
          <b>Edit Company</b>
        </Typography>
        <div style={{padding: "20px"}}>
          <Grid container spacing={2}>
          <Grid item xs={12}>
              <Typography>Company Name *</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Website URL</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={websiteUrl}
                onChange={(e) => setWebsiteUrl(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>Street Address *</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography>City *</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography>State *</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={state}
                onChange={(e) => setState(e.target.value)}
                placeholder="MO"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography>Zip Code *</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography>Country *</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                placeholder="United States"
              />
            </Grid>
            <Grid item xs={12} style={{marginTop: "20px", textAlign: "right"}}>
              <Button 
                variant="contained" 
                style={{marginRight: "20px"}}
                onClick={() => props.onClose()}
              >
                Cancel
              </Button>
              <Button 
                variant="contained"
                onClick={() => submitCompany(
                  props.lead.lead_id, 
                  props.lead.company_id, 
                  companyName ? companyName : null,
                  websiteUrl ? websiteUrl : null,
                  address ? address : null, 
                  city ? city : null,
                  state ? state : null,
                  zipCode ? zipCode : null,
                  country ? country : null
                )} 
                style={{backgroundColor: "#4caf50", color: "#FFFFFF"}}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Dialog>
  )
}