import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { getB2bLogs } from "../../Actions";
import { ChevronLeft, ChevronRight, Info } from "@material-ui/icons";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/moment';
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";

function EdiLogItem(props) {
  const subdomain = props.site?.site_name.replace(".showroompricing.com", "");
  const transferType = (props.edi?.alternate_transfer_type ?? props.partner?.transfer_type ?? "").toUpperCase();
  const status = props.ediLog.status === 'FAIL' ? "Error" : "Success";
  let errorType = "";
  
  if (props.ediLog.status === 'FAIL' && JSON.stringify(props.ediLog?.error_msg).includes("530")) {
    errorType = " - Bad Credentials";
  } else if (props.ediLog.status === 'FAIL' && JSON.stringify(props.ediLog?.error_msg).includes("550")) {
    errorType = " - Bad Command";
  } else if (props.ediLog.status === 'FAIL') {
    errorType = " - Generic";
  }

  return (
    <div style={{borderBottom: "1px solid #838383", textAlign: "left", padding: "10px 10px 25px"}}>
      <Typography variant="h6"><b>{props.site?.company_name}</b></Typography>
      <Typography>{props.partner?.name} {props.edi?.brand ? " (" + props.edi?.brand + ")" : ""}</Typography>
      { props.edi?.secondary_b2b ? (
          <Typography style={{marginBottom: "20px"}}>Primary Provider</Typography>
        ) : (
          <Typography style={{marginBottom: "20px"}}>Secondary Provider {props.site?.external_software_name ? `(${props.site?.external_software_name})` : "" }</Typography>
        )
      }

      <Alert severity={props.ediLog.status === 'FAIL' ? "error" : "success"} style={{width: "300px"}}>
        <b>{transferType} {status}</b> {errorType}
      </Alert>
      
      { props.ediLog?.status === 'FAIL' ? (
          <>
            <div style={{backgroundColor: "#F2F2F2", padding: "2px 10px", marginTop: "5px", minWidth: "300px", maxWidth: "50%"}}>
              <pre style={{whiteSpace: "pre-wrap"}}>
                {JSON.stringify(props.ediLog?.error_msg, null, 2)}
                <Typography variant="inherit" style={{display: "block", paddingTop: "10px"}}>{props.ediLog.created_dttm + " (UTC)"}</Typography>
              </pre>
            </div>
          </>
        ) : (
          <>
            <div style={{backgroundColor: "#F2F2F2", padding: "2px 10px", marginTop: "5px", minWidth: "300px", maxWidth: "50%"}}>
              <pre>
                <p>File Count: {props.ediLog?.file_count}</p>
                {JSON.stringify(props.ediLog?.file_list, null, 2)}
                <Typography variant="inherit" style={{display: "block", paddingTop: "10px"}}>{props.ediLog.created_dttm + " (UTC)"}</Typography>
              </pre>
            </div>
          </>
        )
      }

      { subdomain ? (
          <Button variant="contained" href={`/siteinfo/${subdomain}`} target="_blank" style={{margin: "25px 20px 0px auto"}}>
            Manage Site
          </Button>
        ) : undefined
      }
    </div>
  )
}

export default function B2BLogging(props) {
  const [refresh, setRefresh] = useState(true);
  const [pollingTime, setPollingTime] = useState(true);
  const [pollingStatus, setPollingStatus] = useState();
  const [resetTimeRange, setResetTimeRange] = useState(true);
  const [ediLogs, setEdiLogs] = useState([]);
  const [edis, setEdis] = useState([]);
  const [sites, setSites] = useState([]);
  const [partners, setPartners] = useState([]);
  const [brands, setBrands] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [filter, setFilter] = useState("");
  const [selectedPartnerId, setSelectedPartnerId] = useState("-1");
  const [selectedBrandName, setSelectedBrandName] = useState("-1");
  const [selectedStatus, setSelectedStatus] = useState("-1");
  const [selectedTransferType, setSelectedTransferType] = useState("-1");
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [isStartDateChanged, setIsStartDateChanged] = useState(false);
  const [isEndDateChanged, setIsEndDateChanged] = useState(false);

  const intervalRef = useRef(null);

  useEffect(() => {
    function getData() {
      setPollingTime(new Date().getTime());

      getB2bLogs()
        .then((result) => {
          const combinedObjects = result.data.ediLogs.map(log => {
            const edi = result.data.edis.find(edi => edi.edi_id === log.edi_id);
            const site = edi ? result.data.sites.find(site => site.site_id === edi.site_id) : undefined;
            const partner = edi ? result.data.partners.find(partner => partner.partner_id === edi.partner_id) : undefined;

            return {
              ...log,
              edi,
              site,
              partner
            }
          });

          const endDate = new Date(combinedObjects[0]?.created_dttm?.split('T')[0]);
          const startDate = new Date(endDate.getTime() - (1000 * 60 * 60 * 24 * 30));

          if (!isStartDateChanged) setSelectedStartDate(startDate.toDateString());
          if (!isEndDateChanged) setSelectedEndDate(endDate.toDateString());
          
          setEdiLogs([ ...combinedObjects ]);
          setSites([ ...result.data.sites ]);
          setPartners([ ...result.data.partners ]);
          setEdis([ ...result.data.edis ]);
          setPollingStatus("success");
        })
        .catch((err) => {
          console.log(err);
          setPollingStatus("error");
          clearInterval(intervalRef.current);
        });
    }

    if (refresh) {
      getData();
      setRefresh(false);
    }
  }, [refresh])

  useEffect(() => {
    intervalRef.current = setInterval(() => setRefresh(true), (1000 * 60));
    
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (resetTimeRange && ediLogs?.length) {
      const endDate = new Date(ediLogs[0]?.created_dttm.split('T')[0]);
      const startDate = new Date(endDate.getTime() - (1000 * 60 * 60 * 24 * 30));
      setSelectedEndDate(endDate.toDateString());
      setSelectedStartDate(startDate.toDateString());
      setIsEndDateChanged(false);
      setIsStartDateChanged(false);
      setResetTimeRange(false);
    }
  }, [resetTimeRange, ediLogs]);

  const isFuzzyMatch = (siteName, companyName) => {
    if (!filter) return true;

    const regex = new RegExp(`${filter}`, "gi");
    
    if (siteName && regex.test(siteName)) return true;
    if (companyName && regex.test(companyName)) return true;
    
    return false;
  }

  const passesFilterChecks = (log) => {

    if (new Date(log.created_dttm.split('T')[0]) < new Date(selectedStartDate).getTime() || new Date(log.created_dttm.split('T')[0]).getTime() > (new Date(selectedEndDate).getTime() + (1000 * 60 * 60 * 24) - 1)) {
      return false;
    }

    if (selectedStatus !== "-1" && log.status !== selectedStatus) {
      return false;
    }

    const transferType = (log.edi?.alternate_transfer_type ?? log.partner?.transfer_type ?? "").toUpperCase();

    if (selectedTransferType !== "-1" && transferType !== selectedTransferType) {
      return false;
    }

    if (selectedPartnerId !== "-1" && log.edi?.partner_id !== selectedPartnerId) {
      return false;
    }

    if (selectedPartnerId !== "-1" && selectedBrandName !== "-1" & log.edi?.brand !== selectedBrandName) {
      return false;
    }

    return true;
  } 

  const filteredList = ediLogs.filter((log) => isFuzzyMatch(log.site?.site_name, log.site?.company_name) && passesFilterChecks(log));
  const paginatedList = filteredList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const isFirstPage = page === 0;
  const isLastPage = ((page + 1) * rowsPerPage) >= filteredList.length;
  const currentStart = Math.max(page * rowsPerPage, 1);
  const currentEnd = Math.min((page * rowsPerPage) + rowsPerPage, filteredList.length);
  const rowsPerPageOptions = [10, 25, 50, 100, 500];

  const ediLogItems = paginatedList.map(log => {

    return (
      <EdiLogItem 
        key={log.edi_log_id}
        ediLog={log}
        edi={log.edi}
        site={log.site}
        partner={log.partner}
      />
    )
  })

  return (
    <div className="container">
      <Paper elevation={3}>
        <div className="siteinfo__header">
          <Typography variant="h4">
            B2B Logs 
            <Tooltip 
              title={
                <Typography style={{display: "inline-block", padding: "10px"}}>Logs include records where files were processed or an error occurred in the last 30 days.</Typography>
              } 
              placement="bottom" 
              style={{display: "inline-block", marginLeft: "10px", verticalAlign: "middle"}}
            >
              <Info fontSize="inherit"/>
            </Tooltip>  
          </Typography>
        </div>
        <div style={{textAlign: "left"}}>
          <div style={{borderBottom: "1px solid #838383"}}>
            <Alert severity={pollingStatus} style={{width: "500px", margin: "10px auto 20px 10px"}}>
              <AlertTitle>
                <b>Polling Data</b>
              </AlertTitle>
              Status: {pollingStatus === "error" ? "Error - Please refresh page or contact a dev" : "Success"} 
              <br></br>
              Rate: Every minute
              <br></br>
              Last Updated: {new Date(pollingTime).toLocaleTimeString()}
            </Alert>
            <Grid container spacing={3} style={{padding: "20px 10px"}}>
              <Grid item>
                <Typography style={{margin: "auto auto 5px"}}>Name/Site Search</Typography>
                <Autocomplete
                  inputValue={filter}
                  options={sites}
                  getOptionLabel={(option) => option?.company_name ?? ""}
                  renderOption={(option) => (
                    <span key={option.site_id} id={option.site_id} style={{width: "100%"}}>
                      <Typography variant="body2"><b>{option.company_name}</b></Typography>
                      <Typography variant="body2">{option.site_name?.replace(".showroompricing.com", "")}</Typography>
                    </span>
                  )}
                  filterOptions={(options) => {
                    return options.filter((site) => isFuzzyMatch(site?.site_name, site?.company_name))
                  }}
                  renderInput={(params) => <TextField {...params} label="Name" variant="filled" inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password'
                  }}/>}
                  style={{width: "300px"}}
                  onInputChange={(e, value) => setFilter(value)}
                  onChange={(e, data) => setFilter(data?.company_name ?? "")}
                />
              </Grid>
              <Grid item>
                <Typography style={{margin: "auto auto 5px"}}>Time Range (UTC) 
                  <span 
                    style={{display: "inline-block", verticalAlign: "middle", marginLeft: "20px", textDecoration: "underline", cursor: "pointer"}}
                    onClick={() => setResetTimeRange(true)}
                  >Reset</span>
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-DD"
                    value={selectedStartDate}
                    onChange={(e) => {
                      setSelectedStartDate(e);
                      setIsStartDateChanged(true);
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change subscription end date',
                    }}
                    label="Start"
                    inputVariant="filled"
                    maxDate={selectedEndDate}
                    disabled={!ediLogs?.length}
                    style={{marginRight: "20px", width: "200px"}}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-DD"
                    value={selectedEndDate}
                    minDate={selectedStartDate}
                    onChange={(e) => {
                      setSelectedEndDate(e);
                      setIsEndDateChanged(true);
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change subscription end date',
                    }}
                    label="End"
                    inputVariant="filled"
                    disabled={!ediLogs?.length}
                    style={{width: "200px"}}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item>
                <Typography style={{margin: "auto auto 5px"}}>Status</Typography>
                <Select
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  style={{width: "200px"}} 
                  variant="filled"
                  displayEmpty
                >
                  <MenuItem value={"-1"}>
                    All Statuses
                  </MenuItem>
                  <MenuItem value={"SUCCESS"}>
                    Success
                  </MenuItem>
                  <MenuItem value={"FAIL"}>
                    Error
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item>
                <Typography style={{margin: "auto auto 5px"}}>Transfer Type</Typography>
                <Select
                  value={selectedTransferType}
                  onChange={(e) => setSelectedTransferType(e.target.value)}
                  style={{width: "200px"}} 
                  variant="filled"
                  displayEmpty
                >
                  <MenuItem value={"-1"}>
                    All Transfer Types
                  </MenuItem>
                  <MenuItem value={"FTP"}>
                    FTP
                  </MenuItem>
                  <MenuItem value={"SFTP"}>
                    SFTP
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item>
                <Typography style={{margin: "auto auto 5px"}}>Vendor</Typography>
                <Select
                  value={selectedPartnerId}
                  onChange={(e) => {
                    setSelectedPartnerId(e.target.value);

                    if (e.target.value !== "-1") {
                      const brandsForPartner = [...new Set(ediLogs.filter(log => log.edi?.brand && log.edi.partner_id === e.target.value).map(log => log.edi.brand))];
                      setBrands(brandsForPartner);
                    } 
                      
                    setSelectedBrandName("-1");
                  }}
                  style={{width: "200px"}} 
                  variant="filled"
                  disabled={!partners?.length}
                  displayEmpty
                >
                  <MenuItem value={"-1"}>
                    All Vendors
                  </MenuItem>
                  {partners.map((partner) => {
                    return (
                      <MenuItem key={partner.partner_id} value={partner.partner_id}>
                        {partner.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              { selectedPartnerId !== "-1" ? (
                  <Grid item>
                    <Typography style={{margin: "auto auto 5px"}}>Brand</Typography>
                    <Select
                      value={selectedBrandName}
                      onChange={(e) => setSelectedBrandName(e.target.value)}
                      style={{width: "200px"}} 
                      variant="filled"
                      displayEmpty
                    >
                      <MenuItem value={"-1"}>
                        All Brands
                      </MenuItem>
                      {brands.map((brand) => {
                        return (
                          <MenuItem key={brand} value={brand}>
                            {brand}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                ) : undefined
              }
            </Grid>
            <Grid container spacing={2} style={{padding: "20px 10px", textAlign: "right", justifyContent: "end", alignItems: "center"}}>
              <Grid item>
                <FormControl style={{width: "150px", textAlign: "left"}}>
                  <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
                  <Select
                    labelId="rows-per-page-label"
                    id="rows-per-page"
                    value={rowsPerPage}
                    variant="filled"
                    fullWidth
                    onChange={(e) => setRowsPerPage(e.target.value)}
                  >
                    {rowsPerPageOptions.map(o => {
                      return (
                        <MenuItem key={o} value={o}>{o}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Typography style={{width:"200px"}}>{currentStart} - {currentEnd} of {filteredList.length}</Typography>
              </Grid>
              <Grid item>
                <Button 
                  variant="text" 
                  disabled={isFirstPage} 
                  style={{backgroundColor: "rgba(0, 0, 0, 0.09)", margin: "0px 0px 0px 0px"}} 
                  onClick={() => setPage(isFirstPage ? 0 : page - 1)}
                >
                  <ChevronLeft /> Prev
                </Button>
                <Button 
                  variant="text" 
                  disabled={isLastPage} 
                  style={{backgroundColor: "rgba(0, 0, 0, 0.09)", margin: "0px 0px 0px 15px"}} 
                  onClick={() => setPage(isLastPage ? page : page + 1)}
                >
                  Next <ChevronRight /> 
                </Button>
              </Grid>
            </Grid>
          </div>
          <div style={{textAlign: "left"}}>
            {ediLogItems?.length ? ediLogItems : (
              <Typography style={{padding: "20px 10px", borderBottom: "1px solid #838383"}}>No B2B records found</Typography>
            )}
          </div>
          <Grid container spacing={2} style={{padding: "20px 10px", textAlign: "right", justifyContent: "end", alignItems: "center"}}>
            <Grid item>
              <FormControl style={{width: "150px", textAlign: "left"}}>
                <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
                <Select
                  labelId="rows-per-page-label"
                  id="rows-per-page"
                  value={rowsPerPage}
                  variant="filled"
                  fullWidth
                  onChange={(e) => setRowsPerPage(e.target.value)}
                >
                  {rowsPerPageOptions.map(o => {
                    return (
                      <MenuItem key={o} value={o}>{o}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Typography style={{width:"200px"}}>{currentStart} - {currentEnd} of {filteredList.length}</Typography>
            </Grid>
            <Grid item>
              <Button 
                variant="text" 
                disabled={isFirstPage} 
                style={{backgroundColor: "rgba(0, 0, 0, 0.09)", margin: "0px 0px 0px 0px"}} 
                onClick={() => setPage(isFirstPage ? 0 : page - 1)}
              >
                <ChevronLeft /> Prev
              </Button>
              <Button 
                variant="text" 
                disabled={isLastPage} 
                style={{backgroundColor: "rgba(0, 0, 0, 0.09)", margin: "0px 0px 0px 15px"}} 
                onClick={() => setPage(isLastPage ? page : page + 1)}
              >
                Next <ChevronRight /> 
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </div>
  )
}