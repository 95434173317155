import { Button, Checkbox, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { getInstances, upgradeMultipleServerAMIs, upgradeServerAMI } from "../../Actions";
import { AlertContext } from "../../Context/AlertContextProvider";
import { CheckCircle, RemoveCircle } from "@material-ui/icons";

function TableRecord (props) {

  return (
    <TableRow style={{backgroundColor: props.instance.isUpgraded ? "#F2F2F2" : "initial"}}>
      <TableCell style={{textAlign: "center"}}>
        { !props.instance.isProduction ? 
            undefined 
          : props.instance.isUpgraded ? (
            <RemoveCircle style={{color: "#142e3e"}}/>
          ) : !props.instance.isUpToDate ? (
            <Checkbox checked={!!props.isSelected} onChange={() => props.toggleSelection()}/>
          ) : (
            <CheckCircle style={{color: "#4caf50"}}/>
          )
        }
      </TableCell>
      <TableCell>{props.instance.siteName}</TableCell>
      <TableCell>{props.instance.imageName}</TableCell>
      <TableCell>{props.instance.isProduction?.toString()}</TableCell>
      <TableCell>{props.instance.state}</TableCell>
      <TableCell>{props.instance.dbInstance?.split(".")[0]}</TableCell>
      <TableCell>{props.instance.instanceType}</TableCell>
      <TableCell>{new Date(props.instance.createdDate)?.toLocaleString()}</TableCell>
      <TableCell style={{textAlign: "center"}}>
        { !props.instance.isProduction ? 
            undefined 
          : props.instance.isUpgraded ? (
            <Typography>Upgraded</Typography>
          ) : !props.instance.isUpToDate ? (
            <Button variant="contained" onClick={() => props.upgradeServer()}>Upgrade</Button>
          ) : (
            <Typography>Up To Date</Typography>
          )
        }
      </TableCell>
    </TableRow>
  );
}

const ServerTools = (props) => {
  const [ami, setAmi] = useState();
  const [instances, setInstances] = useState([]);
  const [selected, setSelected] = useState([]);
  const [siteFilter, setSiteFilter] = useState("");
  const [refresh, setRefresh] = useState(true);
  const { handleAlertOpen, setMessageType, setMessage } = useContext(
    AlertContext
  );

  useEffect(() => {
    async function getEC2Instances () {
      await getInstances()
        .then(result => {
          const sortedInstances = result.data.instances?.sort((a, b) => {
            const siteNameA = a.siteName.replace('-OLD', '').toLowerCase();
            const siteNameB = b.siteName.replace('-OLD', '').toLowerCase();

            if (siteNameA < siteNameB) return -1;
            if (siteNameA > siteNameB) return 1;

            return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime();
          }).map((instance, index, instances) => {
            const isUpgraded = instance.siteName.includes('-OLD') && instances.find(i => i.instanceId !== instance.instanceId && instance.siteName.replace('-OLD', '') === i.siteName);
            const isUpToDate = result.data.amiInstance?.name && result.data.amiInstance?.name === instance.imageName;

            return {
              ...instance,
              isUpgraded,
              isUpToDate
            }
          });

          setInstances(sortedInstances);
          // setImages(result.data.images);
          setAmi(result.data.amiInstance);
        })
        .catch(err => {
          setInstances([]);
          // setImages([]);
          setAmi(undefined);
        });
    }

    if (refresh) {
      setSelected([]);
      setInstances([]);
      setAmi(undefined);
      getEC2Instances();
      setRefresh(false);
    }
  }, [refresh])

  const toggleSelection = (selection, isSelected) => {

    if (isSelected) {
      setSelected([ 
        ...selected, 
        selection
      ]);
    } else {
      const newSelectionArr = selected?.filter(i => i.instanceId !== selection.instanceId);

      setSelected([ ...newSelectionArr ]);
    }
  }

  const toggleAllSelection = (areAllSelected) => {
    if (areAllSelected) {
      setSelected([]);
    } else {
      const newSelectedArr = instances.filter(instance => instance.siteName.toLowerCase().includes(siteFilter) && !instance.isUpgraded && !instance.isUpToDate && instance.isProduction).map(instance => {
        return {
          schemaName: instance.siteName,
          dbInstance: instance.dbInstance,
          isProduction: instance.isProduction,
          instanceId: instance.instanceId
        }
      });

      setSelected(newSelectedArr);
    }
  }

  const upgradeServer = async (instanceData) => {
    await upgradeServerAMI(instanceData.schemaName, instanceData.dbInstance, instanceData.isProduction, instanceData.instanceId)
      .then(result => {
        setMessage("Server upgraded successfully!");
        setMessageType("success");
        handleAlertOpen();
        setRefresh(true);
      }).catch(err => {
        setMessage("Error upgrading server");
        setMessageType("error");
        handleAlertOpen();
      });
  }

  const upgradeSelectedServers = async (selectedInstances) => {
    await upgradeMultipleServerAMIs(selectedInstances)
      .then(result => {
        setMessage("Servers upgraded successfully!");
        setMessageType("success");
        handleAlertOpen();
        setRefresh(true);
      }).catch(err => {
        setMessage("Error upgrading servers");
        setMessageType("error");
        handleAlertOpen();
      });
  }

  const upgradableInstances = instances?.filter(instance => !instance.isUpgraded && !instance.isUpToDate);
  const selectableInstanceCount = upgradableInstances?.filter(instance => instance.siteName.toLowerCase().includes(siteFilter) && !instance.isUpgraded && !instance.isUpToDate)?.length;
  const filteredInstances = instances?.filter(instance => instance.siteName.toLowerCase().includes(siteFilter));

  return (
    <div>
      <Paper 
        elevation={3}
      >
        <div className="header-paper">
          <Typography className="title" variant="h4">
            Server Tools
          </Typography>
        </div>
        <Grid container spacing={3} style={{padding: "30px 20px 20px"}}>
          <Grid item xs={8}>
            <Typography style={{margin: "10px"}}>Upgrading to AMI: {ami?.name}</Typography>
            <Typography style={{margin: "10px"}}>The AMI being upgraded to is based on the AMI id set in the site config</Typography>
            <Typography style={{margin: "15px 10px 0px"}}><b>Site Filter</b></Typography>
            <TextField
              variant="filled"
              value={siteFilter}
              placeholder="Search by site name"
              onChange={(e) => {
                setSelected([]);
                setSiteFilter(e.target.value);
              }}
              style={{margin: "0px 10px 10px"}}
            />
          </Grid>
          <Grid item xs={4} style={{textAlign: "right"}}>
            <Typography style={{margin: "10px 10px 5px"}}><b>{instances.length} Instances</b></Typography>
            <Typography style={{margin: "5px 10px"}}><b>{upgradableInstances.length} Upgradable</b></Typography>
            <Typography style={{margin: "5px 10px 10px"}}><b>{selected.length} Selected</b></Typography>
            <Button variant="contained" onClick={() => upgradeSelectedServers(selected)} style={{margin: "10px"}}>Upgrade Selected</Button>
          </Grid>
          <Grid item xs={12} style={{textAlign: "right"}}>
            { selected.length ? (
                <Button variant="contained" onClick={() => setSelected([])} style={{margin: "10px"}}>Clear Selection</Button>
              ) : undefined
            }
            <Button variant="contained" onClick={() => setRefresh(true)} style={{margin: "10px"}}>Refresh Data</Button>
          </Grid>
          <Grid item xs={12}>
            { instances.length ? (
                <TableContainer>
                  <Table>
                    <TableHead style={{backgroundColor: "#142e3e"}}>
                      <TableCell style={{textAlign: "center"}}> 
                        <Checkbox 
                          style={{color: "#FFFFFF"}} 
                          checked={selectableInstanceCount === selected.length} 
                          onChange={() => toggleAllSelection(selectableInstanceCount === selected.length)}
                        />
                      </TableCell>
                      <TableCell style={{color: "#FFFFFF"}}>
                        <Typography><b>Site</b></Typography>
                      </TableCell>
                      <TableCell style={{color: "#FFFFFF"}}>
                        <Typography><b>AMI</b></Typography>
                      </TableCell>
                      <TableCell style={{color: "#FFFFFF"}}>
                        <Typography><b>Production</b></Typography>
                      </TableCell>
                      <TableCell style={{color: "#FFFFFF"}}>
                        <Typography><b>State</b></Typography>
                      </TableCell>
                      <TableCell style={{color: "#FFFFFF"}}>
                        <Typography><b>DB</b></Typography>
                      </TableCell>
                      <TableCell style={{color: "#FFFFFF"}}>
                        <Typography><b>Server Type</b></Typography>
                      </TableCell>
                      <TableCell style={{color: "#FFFFFF"}}>
                        <Typography><b>Created</b></Typography>
                      </TableCell>
                      <TableCell style={{color: "#FFFFFF"}}></TableCell>
                    </TableHead>
                    <TableBody>
                      {
                        filteredInstances?.map((instance) => {
                          const isSelected = !!selected?.find(i => i.schemaName === instance.siteName); 
                          const instanceData = {
                            schemaName: instance.siteName,
                            dbInstance: instance.dbInstance,
                            isProduction: instance.isProduction,
                            instanceId: instance.instanceId
                          };

                          return (
                            <TableRecord 
                              key={instance.instanceId} 
                              instance={instance} 
                              configImage={ami?.name}
                              isSelected={isSelected}
                              toggleSelection={() => toggleSelection(instanceData, !isSelected)}
                              upgradeServer={() => upgradeServer(instanceData)}
                            />
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <div style={{margin: "20px", textAlign: "center"}}>
                  <CircularProgress size="75px"></CircularProgress>
                </div>
              )
            }
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default ServerTools;