import React, { useState, createContext, useEffect } from "react";
import axios from "axios";
import { getUser } from "../helpers";
import { baseUrl } from "../Contants";

export const AdminContext = createContext();

export const AdminContextProvider = ({ children }) => {
  const [user] = useState(getUser());
  const [users, setUsers] = useState();
  const [accesstypes, setAccesstypes] = useState([]);
  const [update, setUpdate] = useState(false);

  // /admin/accesstypes

  const isAdmin = user.access_type_cd === "a" ? true : false;

  useEffect(() => {
    async function getUsers() {
      const result = await axios(`${baseUrl}/api/admin/users`);
      setUsers(result.data.data);
    }

    async function getAccesstypes() {
      const result = await axios(`${baseUrl}/api/admin/accesstypes`);
      setAccesstypes(result.data.data);
    }
    if (isAdmin) {
      getUsers();
      getAccesstypes();
      setUpdate(false);
    }
  }, [isAdmin, update]);

  return (
    <AdminContext.Provider value={{ users, setUpdate, accesstypes, setUsers }}>
      {children}
    </AdminContext.Provider>
  );
};
