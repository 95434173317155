import { Button, Chip, Grid, Typography } from "@material-ui/core";
import { AccountBalance, Computer, Group, Hearing, Launch, Store } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import React from "react";

export default function LeadListItem(props) {

  const marketList = props.lead.markets.map(market => {
    return (
      <Chip
        key={market.market_id}
        label={market.name}
        style={{backgroundColor: "#142e3e", color: "#28c4fc", border: "0px", margin: "5px 10px 5px 0px"}}
        variant="outlined"
      />
    )
  });

  const externalSoftwareName = props.externalSoftwares.find(s => s.external_software_id === props.lead.external_software_id)?.name;
  const affiliateGroupName = props.affiliateGroups.find(g => g.affiliate_group_id === props.lead.affiliate_group_id)?.name;
  const referralTypeName = props.referralTypes.find(r => r.referral_type_id === props.lead.referral_type_id)?.name;
  const revenueTierDescription = props.revenueTiers.find(r => r.revenue_tier_id === props.lead.revenue_tier_id)?.description;
  const assignedToName = props.lead.assigned_to ? props.users.find(u => u.user_id === props.lead.assigned_to)?.name : undefined;
  const leadStatusName = props.statuses.find(s => s.lead_status_id === props.lead.lead_status_id)?.name;

  let leadStatusSeverity;

  switch (props.lead.lead_status_id) {
    case 2:
    case 3:
    case 5:
    case 6:
    case 9:
      leadStatusSeverity = "warning";
      break;
    case 7:
    case 8:
      leadStatusSeverity = "success";
      break;
    case 4:
      leadStatusSeverity = "error";
      break;
    default:
      leadStatusSeverity = "info";
  }

  return (
    <div style={{padding: "20px", borderBottom: "1px solid #838383"}}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <Typography variant="h5"><b>{props.lead.company?.company}</b></Typography>
            { props.lead.company?.website_url ? (
                <Typography>
                  <a href={`${!props.lead.company?.website_url.includes("http") ? "http://" + props.lead.company?.website_url : props.lead.company?.website_url}`} target="_blank" style={{ textDecoration: "none", cursor: "pointer", color: "#838383"}}>
                    <Launch style={{display: "inline-block", marginRight: "3px", verticalAlign: "middle"}}></Launch> {props.lead.company?.website_url}
                  </a>
                </Typography>
              ) : undefined
            }
            <Typography>{props.lead.company?.city}, {props.lead.company?.state} {props.lead.company?.country}</Typography>
            <Typography>{assignedToName ? "Assigned To: " + assignedToName : "Unassigned"}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Alert severity={leadStatusSeverity} style={{marginTop: "20px", maxWidth: "250px"}}>{leadStatusName}</Alert>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <Typography variant="h6"><b>Markets Served</b></Typography>
            {marketList}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" style={{marginTop: "15px", marginBottom: "5px"}}><b>Additional Attributes</b></Typography>
            <Grid container spacing={3}>
              { props.lead.revenue_tier_id ? (
                  <Grid item>
                    <Typography>
                      <AccountBalance fontSize="small" style={{display: "inline-block", verticalAlign: "top", marginRight: "5px"}}/>
                      {revenueTierDescription}
                    </Typography>
                  </Grid>
                ) : undefined
              }
              { props.lead.location_count ? (
                  <Grid item>
                    <Typography>
                      <Store fontSize="small" style={{display: "inline-block", verticalAlign: "top", marginRight: "5px"}}/>
                      {props.lead.location_count + " Location" + (props.lead.location_count > 1 ? "s" : "")}
                    </Typography>
                  </Grid>
                ) : undefined
              }
              { props.lead.external_software_id ? (
                  <Grid item>
                    <Typography>
                      <Computer fontSize="small" style={{display: "inline-block", verticalAlign: "top", marginRight: "5px"}}/>
                      {externalSoftwareName}
                    </Typography>
                  </Grid>
                ) : undefined
              }
              { props.lead.affiliate_group_id ? (
                  <Grid item>
                    <Typography>
                      <Group fontSize="small" style={{display: "inline-block", verticalAlign: "top", marginRight: "5px"}}/>
                      {affiliateGroupName}
                    </Typography>
                  </Grid>
                ) : undefined
              }
              { props.lead.referral_type_id ? (
                  <Grid item>
                    <Typography>
                      <Hearing fontSize="small" style={{display: "inline-block", verticalAlign: "top", marginRight: "5px"}}/>
                      {"Referred From " + referralTypeName}
                    </Typography>
                  </Grid>
                ) : undefined
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" style={{marginTop: "15px"}} href={`/lead/${props.lead.lead_id}`} target="_blank">Manage Lead</Button>
        </Grid>
      </Grid>
    </div>
  )
}