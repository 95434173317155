import React, { useContext } from "react";

// styles
import "../../styles/Admin.css";

// Actions
import { deleteUser, updateUser } from "../../../Actions";

// MUI
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// dependents
import { AdminContext } from "../../../Context/AdminContextProvider";
import { AlertContext } from "../../../Context/AlertContextProvider";

const EditUser = (props) => {
  const [user, setUser] = React.useState(props.user);
  const [access, setAccess] = React.useState("");
  const [accessCode, setAccessCode] = React.useState("");

  const { setUpdate, accesstypes } = useContext(AdminContext);
  const { handleAlertOpen, setMessageType, setMessage } = useContext(
    AlertContext
  );

  React.useEffect(() => {
    setUser(props.user);
    setAccess(props.user.accessType);
  }, [props.user]);

  const handleUserDelete = (userid) => {
    deleteUser(userid)
      .then((res) => {
        setUpdate(true);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdateUser = (userId) => {
    updateUser(userId, accessCode)
      .then((result) => {
        setMessage("Updated user access");
        setMessageType("success");
        handleAlertOpen();
        setUpdate(true);
      })
      .catch((err) => {
        setMessage("Failed to update user");
        setMessageType("error");
        handleAlertOpen();
      });
  };

  const handleAccessChange = (e) => {
    const accessType = e.target.value;
    setAccess(accessType);
    const tempAccess = accesstypes.filter(
      (access) => access.access_type === accessType
    )[0];
    setAccessCode(tempAccess.access_type_cd);
  };

  const getAccessTypes = (data) => {
    return (
      <Select
        className="admin__set-user-access"
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        value={access}
        onChange={handleAccessChange}
        displayEmpty
      >
        {data.map((access) => {
          return (
            <MenuItem key={access.access_type} value={access.access_type}>
              {access.access_type}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const renderUser = (user) => {
    return user.userId ? (
      <div>
        <Paper className="admin__edit-user-from">
          <Typography className="admin__edit-user-header" variant="h5">
            User
          </Typography>
          <table>
            <tbody>
              <tr>
                <td>Name:</td>
                <td>{user.name}</td>
              </tr>
              <tr>
                <td>User Name:</td>
                <td>{user.userName}</td>
              </tr>
              <tr>
                <td>Access:</td>
                <td>{getAccessTypes(accesstypes)}</td>
              </tr>
            </tbody>
          </table>
          <div className="admin__form-actions">
            <Button
              onClick={() => handleUpdateUser(user.userId)}
              className="admin__update-user"
            >
              Update
            </Button>

            <Button
              onClick={() => handleUserDelete(user.userId)}
              className="admin__delete-user"
            >
              Delete
            </Button>
          </div>
        </Paper>
      </div>
    ) : (
      <div>Select User</div>
    );
  };

  return <div>{renderUser(user)}</div>;
};

export default EditUser;
