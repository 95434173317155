import React, { useContext, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

// styles
import "../styles/Admin.css";

// denpendents
import { AdminContext } from "../../Context/AdminContextProvider";
import ManageUsers from "./Users/ManageUsers";
import ManageAnnouncements from "./Announcements/ManageAnnouncements";
import ManageBulletin from "./Bulletin/ManageBulletin";
import NewSite from "../NewSite/Newsite";
import Videos from "../Videos/Videos";

const Admin = () => {
  const [value, setValue] = useState("users");
  const { users } = useContext(AdminContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `wrapped-tab-${index}`,
      "aria-controls": `wrapped-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  return (
    <div className="admin__container">
      <Paper className="admin__tab-controller">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab value="users" label="Users" {...a11yProps("users")} />
          <Tab
            value="announcement"
            label="Announcement"
            {...a11yProps("announcement")}
          />
          <Tab value="bulletin" label="Bulletin" {...a11yProps("bulletin")} />
          <Tab value="NewSite" label="Launch A New Site" {...a11yProps("NewSite")} />
          <Tab value="Videos" label="Instructional videos" {...a11yProps("Videos")} />
        </Tabs>
      </Paper>
      <TabPanel value={value} index="users">
        <ManageUsers users={users} />
      </TabPanel>
      <TabPanel value={value} index="announcement">
        <ManageAnnouncements />
      </TabPanel>
      <TabPanel value={value} index="bulletin">
        <ManageBulletin />
      </TabPanel>
      <TabPanel value={value} index="NewSite">
        <NewSite />
      </TabPanel>
      <TabPanel value={value} index="Videos">
        <Videos />
      </TabPanel>
    </div>
  );
};

export default Admin;
