import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AppContext } from "../Context/AppContextProvide";

function AdminAuthRoute({ component: Component, authed, ...rest }) {
  const { user } = React.useContext(AppContext);
  const isAuthenticated = new Date().getTime() < user?.will_expire_at;
  const isAdmin = user?.access_type_cd === "a" ? true : false;

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated && isAdmin === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/notfound", state: { from: props.location } }}
          />
        );
      }}
    />
  );
}

export default AdminAuthRoute;
