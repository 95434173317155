import React, { useState, createContext, useEffect, useRef } from "react";
import axios from "axios";
import { getUser as getLocalUser, setUser as setLocalUser } from "../helpers";
import { baseUrl } from "../Contants";
import { logout } from "../Actions";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const DELAY = 5000;
  const [user, setUser] = useState(getLocalUser());
  const [loading, setLoading] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [authType, setAuthType] = useState("");
  const [update, setUpdate] = useState(false);

  const location = useLocation();
  const intervalRef = useRef(null);

  async function handleLogout() {
    setUser({});
    await logout();
    setIsLoggedOut(true);
    clearInterval(intervalRef.current);
  }

  async function handleSessionCheck() {
    if (!user.will_expire_at) return;

    const currentTime = new Date().getTime();

    if (currentTime >= user.will_expire_at) handleLogout();
  }

  useEffect(() => {
    async function getUser(url) {
      setLoading(true);
      const result = await axios(url);
      setUser(result.data);
      setLocalUser(result.data);
      setLoading(false);
      setIsLoggedOut(false);
    }

    if (accessToken && authType === "google")
      getUser(`${baseUrl}/api/auth/google/user`);
    if (accessToken && authType === "local")
      getUser(`${baseUrl}/api/auth/local/user`);

    setUpdate(false);

    intervalRef.current = setInterval(() => handleSessionCheck(), DELAY);

    return () => clearInterval(intervalRef.current);
  }, [accessToken, authType, update, DELAY]);

  useEffect(() => {
    if (user.will_expire_at) handleSessionCheck();
  }, [location])

  return (
    <AppContext.Provider
      value={{
        user,
        setAccessToken,
        setUser,
        setLoading,
        loading,
        setIsLoggedOut,
        isLoggedOut,
        setAuthType,
        setUpdate,
        handleLogout
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
