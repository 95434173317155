import React, { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../Context/AlertContextProvider";
import { updateCompany, updateSelectedSiteContact, updateSiteContact } from "../../Actions";
import { Button, Chip, Dialog, Grid, Paper, TextField, Typography } from "@material-ui/core";


export function UpdateCompanyDialog(props) {
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");

  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);

  useEffect(() => {
    setCompanyName(props.company ?? "");
    setAddress(props.address ?? "");
    setCity(props.city ?? "");
    setState(props.state ?? "");
    setZipCode(props.zip ?? "");
    setCountry(props.country ?? "United States");
    setWebsiteUrl(props.website ?? "");
  }, [props.isOpen])
  

  const submitCompany = async (companyId, name, websiteUrl, address, city, state, zipCode, country) => {

    if (!name || !address || !city || !state || !zipCode || !country) {
      setMessage("Error updating company. Please check required fields");
      setMessageType("error");
      handleAlertOpen();

      return;
    }

    // TODO: list out all contacts (or give a way to veiw them all) and allow the user to set one as the site contact (give it the site id)

    await updateCompany(companyId, name, websiteUrl, address, city, state, zipCode, country)
      .then(result => {
        setMessage("Successfully updated company!");
        setMessageType("success");
        handleAlertOpen();
    
        props.refreshData();
        props.onClose();
        return;
      }).catch(err => {
        setMessage("Error updating company");
        setMessageType("error");
        handleAlertOpen();
        
        return;
      });
  }

  return (
    <Dialog
      open={props.isOpen} 
      onClose={() => props.onClose()}
      maxWidth={false}
    >
      <Paper style={{width: "45vw", minWidth: "300px"}}>
        <Typography 
          className="siteinfo__header"
          variant="h4" 
        >
          <b>Edit Company</b>
        </Typography>
        <div style={{padding: "20px"}}>
          <Grid container spacing={2}>
          <Grid item xs={12}>
              <Typography>Company Name *</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>Website URL</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={websiteUrl}
                onChange={(e) => setWebsiteUrl(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography>Street Address *</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography>City *</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography>State *</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={state}
                onChange={(e) => setState(e.target.value)}
                placeholder="MO"
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography>Zip Code *</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <Typography>Country *</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                placeholder="United States"
              />
            </Grid>
            <Grid item xs={12} style={{marginTop: "20px", textAlign: "right"}}>
              <Button 
                variant="contained" 
                style={{marginRight: "20px"}}
                onClick={() => props.onClose()}
              >
                Cancel
              </Button>
              <Button 
                variant="contained"
                onClick={() => submitCompany(
                  props.companyId, 
                  companyName ? companyName : null,
                  websiteUrl ? websiteUrl : null,
                  address ? address : null, 
                  city ? city : null,
                  state ? state : null,
                  zipCode ? zipCode : null,
                  country ? country : null
                )} 
                style={{backgroundColor: "#4caf50", color: "#FFFFFF"}}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Dialog>
  )
}

export function UpdateContactDialog(props) {
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);

  useEffect(() => {
    setContactName(props.name ?? "");
    setEmail(props.email ?? "");
    setPhone(props.phone ?? "");
  }, [props.isOpen])
  

  const submitContact = async (contactId, contactName, email, phone) => {

    if (!contactName) {
      setMessage("Error updating contact. Contact name missing");
      setMessageType("error");
      handleAlertOpen();

      return;
    }

    if (!email && !phone) {
      setMessage("Error updating contact. Either an email or a phone number is required");
      setMessageType("error");
      handleAlertOpen();

      return;
    }

    await updateSiteContact(contactId, contactName, email, phone)
      .then(result => {
        setMessage("Successfully updated contact!");
        setMessageType("success");
        handleAlertOpen();
    
        props.refreshData();
        props.onClose();
      }).catch(err => {
        setMessage("Error updating contact");
        setMessageType("error");
        handleAlertOpen();

        return;
      });
  }

  return (
    <Dialog
      open={props.isOpen} 
      onClose={() => props.onClose()}
      maxWidth={false}
    >
      <Paper style={{width: "45vw", minWidth: "300px"}}>
        <Typography 
          className="siteinfo__header"
          variant="h4" 
        >
          <b>Edit Contact</b>
        </Typography>
        <div style={{padding: "20px"}}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography>Name *</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
                placeholder="First Last"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Email</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="example@test.com"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Phone</Typography>
              <TextField
                fullWidth
                variant="filled"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="012-345-6789"
              />
            </Grid>
            <Grid item xs={12} style={{marginTop: "20px", textAlign: "right"}}>
              <Button 
                variant="contained" 
                style={{marginRight: "20px"}}
                onClick={() => props.onClose()}
              >
                Cancel
              </Button>
              <Button 
                variant="contained"
                onClick={() => submitContact(props.contactId, contactName, email ? email : null, phone ? phone : null)} 
                style={{backgroundColor: "#4caf50", color: "#FFFFFF"}}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Dialog>
  )
}

export function ContactListDialog(props) {
  const { handleAlertOpen, setMessageType, setMessage } = useContext(AlertContext);

  const setAsSiteContact = async (siteId, contactId) => {

    await updateSelectedSiteContact(siteId, contactId)
      .then(result => {
        setMessage("Successfully updated the selected site contact!");
        setMessageType("success");
        handleAlertOpen();
    
        props.refreshData();
        props.onClose();
      }).catch(err => {
        setMessage("Error updating the selected site contact");
        setMessageType("error");
        handleAlertOpen();

        return;
      });
  }

  const contactList = props.contacts?.map(contact => {
    const isSiteContact = contact.site_id !== null;
  
    return (
      <div key={contact.contact_id} style={{padding: "15px 0px"}}>
        <Typography variant="h6">
          <b>{contact.name}</b> 
          { isSiteContact ? (
              <Chip 
                size="small"
                label={"Site Contact"}
                style={{backgroundColor: "#142e3e", color: "#28c4fc", borderColor: "#142e3e", marginLeft: "10px"}}
              />
            ) : undefined
          }
        </Typography>
        <Typography>{contact.phone}</Typography>
        <a href={`mailto:${contact.email}`}><Typography>{contact.email}</Typography></a>
        { !isSiteContact ? (
            <div style={{marginTop: "10px"}}>
              <Button 
                size="small"
                variant="contained"
                style={{backgroundColor: "#4caf50", color: "#FFFFFF"}}
                onClick={() => setAsSiteContact(props.siteId, contact.contact_id)}
              >
                Set As Site Contact
              </Button>
            </div>
          ) : undefined 
        }
      </div>
    )
  });

  return (
    <Dialog
      open={props.isOpen} 
      onClose={() => props.onClose()}
      maxWidth={false}
    >
      <Paper style={{width: "45vw", minWidth: "300px"}}>
        <Typography 
          className="siteinfo__header"
          variant="h4" 
        >
          <b>All Contacts</b>
        </Typography>
        <div style={{padding: "20px"}}>
          <Grid container>
            <Grid item xs={12}>
              <Typography>This list includes the current site contact as well as any contacts connected to the lead for this site.</Typography>
              <Typography style={{marginTop: "10px"}}>Please use the list below to set a new site contact. To add or edit any contacts outside of the site contact, please go to the lead page using the button below.</Typography>
            </Grid>
            <Grid item xs={12} style={{maxHeight: "600px", overflowY: "auto", marginTop: "20px", border: "1px solid #ccc", padding: "10px 10px 30px"}}>
              {contactList}
            </Grid>
            <Grid item xs={12} style={{marginTop: "20px", textAlign: "right"}}>
              <Button 
                variant="contained"
                style={{marginRight: "20px"}} 
                onClick={() => props.onClose()}
              >
                Close
              </Button>
              <Button 
                variant="contained" 
                style={{backgroundColor: "#142e3e", color: "#28c4fc"}}
                target="_blank"
                href={`/lead/${props.contacts[0]?.lead_id}`}
              >
                Lead Page
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Dialog>
  )
}